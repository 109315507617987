import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ChartOverlayWrapper from './ChartOverlayWrapper';

function EconomicCyclesChart() {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState([]);

    // useEffect(() => {
    //     fetch('/data-ecr.csv')
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.text();
    //         })
    //         .then((csvData) => {
    //             const parsedData = parseCSV(csvData);
    //             setData(parsedData);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching CSV data:', error);
    //         });
    // }, []);

    // const parseCSV = (csvData) => {
    //     const lines = csvData.split('\n');
    //     const rows = lines.slice(1);

    //     const x = [];
    //     const y = { contraction: [], recovery: [], expansion: [] };

    //     rows.forEach((row) => {
    //         const columns = row.split(',');
    //         const date = columns[0];
    //         const contraction = columns[1] !== '' ? parseFloat(columns[1]) : null;
    //         const recovery = columns[2] !== '' ? parseFloat(columns[2]) : null;
    //         const expansion = columns[3] !== '' ? parseFloat(columns[3]) : null;

    //         const parsedDate = new Date(date);
    //         if (
    //             !isNaN(parsedDate.getTime()) &&
    //             parsedDate.getFullYear() >= 1929 &&
    //             parsedDate.getFullYear() <= 2024
    //         ) {
    //             x.push(parsedDate);
    //             y.contraction.push(contraction);
    //             y.recovery.push(recovery);
    //             y.expansion.push(expansion);
    //         }
    //     });

    //     return { x, y };
    // };

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/expansions-contractions`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setData(await response.json());
            } catch (e) {
                console.error(e);
            }
        })();
    }, [getAccessTokenSilently]);

    if (!data || data.length === 0) {
        return <div>Loading Economic Cycles chart...</div>;
    }

    const globalStartDate = data.reduce((min, current) => {
        const currentDate = new Date(current.StartDate);
        return currentDate < min ? currentDate : min;
    }, new Date('9999-12-31'));

    const minYear = globalStartDate.getFullYear();

    // Since return of a recovery is suspect, pin to the max
    // of non-recovery items.
    const maxReturn = Math.max(
        ...data
            .filter(item => item.Phase !== 'Recovery')
            .map(item => Math.abs(item.Return))
    );

    const expansions = data.filter((item) => item.Phase === 'Expansion');
    const downturns = data.filter((item) => item.Phase === 'Downturn');
    const recoveries = data.filter((item) => item.Phase === 'Recovery');

    const calculateXPositionInYears = (startDate, width) => {
        const localStartDate = new Date(startDate);
        const yearsFromGlobalStartDate =
            (localStartDate - globalStartDate) / (1000 * 60 * 60 * 24 * 365);
        const xPositionInYears = yearsFromGlobalStartDate + 0.5 * width;
        return xPositionInYears + minYear;
    };

    const calculateWidth = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const widthInYears = (end - start) / (1000 * 60 * 60 * 24 * 365);
        return widthInYears;
    };

    const calculateDurationInMonths = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diffInMonths =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());

        return diffInMonths;
    };

    const generateTraces = (phaseData, negative, color) => {

        return phaseData.map((item) => {
            const phase = item.Phase;
            const isFirstOccurrence = !(phase in legendStatus); // Check if phase is encountered for the first time

            // Set showlegend based on isFirstOccurrence
            const showLegend = isFirstOccurrence;

            // Update legendStatus for the phase
            legendStatus[phase] = true;

            const itemYValue = phase === 'Recovery'
                ? [negative === true ? -100 : 100]  // Set y to -100 or 100 based on negative
                : [
                    negative === true
                        ? Math.max(-100, Math.min(100, (-1 * 100 * item.Return) / maxReturn))
                        : Math.max(-100, Math.min(100, (100 * item.Return) / maxReturn))
                ]

            const itemYPercentage = parseFloat(itemYValue[0].toFixed(2))

            return {
                x: [
                    calculateXPositionInYears(
                        item.StartDate,
                        calculateWidth(item.StartDate, item.EndDate)
                    ),
                ],
                y: itemYValue,
                width: [calculateWidth(item.StartDate, item.EndDate)],
                marker: { color: color },
                name: phase,
                hoverinfo: 'text',
                showlegend: showLegend, // Set showlegend based on isFirstOccurrence
                legendgroup: phase,
                hovertext: `<b>${phase}</b></br>Start Date: ${new Date(item.StartDate).toISOString().split('T')[0]
                    }<br />End Date: ${new Date(item.EndDate).toISOString().split('T')[0]
                    }<br />Duration (Months): ${calculateDurationInMonths(
                        item.StartDate,
                        item.EndDate
                    )}</br>Percentage: ${itemYPercentage}%`,
                type: 'bar',
                orientation: 'v',
                xaxis: 'x2',
                yaxis: 'y2',
            };
        });
    };

    const legendStatus = {}; // Object to track legend status by phase
    const traces = [
        ...generateTraces(expansions, false, 'rgba(123, 207, 235, 0.7)'),
        ...generateTraces(downturns, false, 'rgba(0, 168, 225, 0.7)'),
        ...generateTraces(recoveries, false, 'rgba(204, 238, 249, 0.7)'),
        ...generateTraces(recoveries, true, 'rgba(204, 238, 249, 0.7)')
    ];

    // If we end up turning the y-axis for SBBI back on in ChartOverlayWrapper.
    // This and the tickvals, ticktext properties will just show 100% and (100%) like in the Figma
    const yAxisTicks = {
		tickValues: [
		  -100,
		  100
		],
		tickText: [
		  '(100%)',
		  '100%',
		]
	  };

    const yAxisSettings =
	{
		side: 'left',
        showgrid: false,
        // tickvals: yAxisTicks.tickValues,
      	// ticktext: yAxisTicks.tickText,
    }

    const xAxisSettings = {
		showgrid: false,
        showticklabels: false,
	};

    return (
        <div style={{ width: '100%', minWidth: '300px' }}>
            <ChartOverlayWrapper singleChartData={traces} singleChartXAxisSettings={xAxisSettings} singleChartYAxisSettings={yAxisSettings} />
        </div>
    );
}

export default React.memo(EconomicCyclesChart); // Memo to keep charts from redrawing when exporting image
