import {useContext} from 'react';
import { ReactComponent as SettingsIcon } from '../../images/settings_icon.svg';
import '../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import '../../css/Buttons/ChartTypeButton.css';
import { CustomizeContext } from '../../context/CustomizeContext';

const CustomizeButton = () => {
  const { 
    customizeSettings, 
    setIsCustomizeMenuVisible
} = useContext(CustomizeContext);

  return (
    <div className='customize-container font-weight-400'>
      <button onClick={() => setIsCustomizeMenuVisible(!customizeSettings.isCustomizeMenuVisible)} className={`customize-button chart-type-button ${customizeSettings.isCustomizeMenuVisible ? 'selected' : ''}`}>
        Customize <span className='customize-button-icon' role="img" aria-label="settings"><SettingsIcon /></span>
      </button>
    </div>
  );
};

export default CustomizeButton;
