import React, { forwardRef } from 'react';
import '../../css/Card/PageContentCard.css';
import PageContentCardHeader from './Headers/PageContentCardHeader';
import PageContentCardFooter from './Footers/PageContentCardFooter';

const PageContentCard = forwardRef(({
    title,
    selectedSideMenuItem,
    date,
    children,
    togglePresentationMode,
    isPresetationModeOn,
    exportChart,
}, ref) => {
    return (
        <div className="page-content-card" ref={ref}>
            <PageContentCardHeader
                title={title}
                selectedSideMenuItem={selectedSideMenuItem}
                togglePresentationMode={togglePresentationMode}
                isPresetationModeOn={isPresetationModeOn}
                exportChart={exportChart}
                date={date}
            />
            {children}
            <PageContentCardFooter date={date} />
        </div>
    );
});

export default PageContentCard;
