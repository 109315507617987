import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Plot from 'react-plotly.js';

const AssetFlowsChart = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [rawChartData, setRawChartData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/asset-flows`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                var data = await response.json();
                data = data.map((x) => ({
                    ...x,
                    YearFull: x.Date.substring(0, 4),
                    YearShort: x.Date.substring(2, 4),
                }));
                setRawChartData(data);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [getAccessTokenSilently]);

    const years = rawChartData.map((data) => data.YearFull);
    const yearsShort = rawChartData.map((data) => data.YearShort);

    const chartData = [
        {
            type: 'bar',
            x: years,
            y: rawChartData.map((data) => data.US_Equity),
            name: 'U.S. Stock',
            marker: { color: 'rgb(23,104,180)' },
        },
        {
            type: 'bar',
            x: years,
            y: rawChartData.map((data) => data.International_Equity),
            name: "Int'l Stock",
            marker: { color: 'rgb(171,29,54)' },
        },
        {
            type: 'bar',
            x: years,
            y: rawChartData.map((data) => data.Taxable_Bond),
            name: 'Bond',
            marker: { color: 'rgb(240,118,34)' },
        },
    ];

    if (rawChartData.length === 0) {
        return <div>Loading Asset Flows chart...</div>;
    }

    return (
        <div style={{ width: '100%', minWidth: '300px' }}>
            <Plot
                divId='PlotlyGraph'
                data={chartData}
                layout={{
                    height: 400,
                    showlegend: true,
                    barmode: 'group',
                    xaxis: {
                        tickvals: years,
                        ticktext: yearsShort,
                        side: 'top',
                        showgrid: true,
                    },
                    yaxis: {
                        range: [-400, 400],
                        tickvals: [-400, -300, -200, -100, 0, 100, 200, 300, 400],
                        title: {
                            font: { color: 'rgba(0,0,0,0.5)' },
                            text: 'Annual Net Asset Fund Flows ($Billion)',
                        },
                    },
                }}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                config={{ displayModeBar: false }}
            />
        </div>
    );
};

export default React.memo(AssetFlowsChart); // Memo to keep charts from redrawing when exporting image
