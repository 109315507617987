import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getIndexInfo } from '../../helpers';
import StandardTable from './StandardTable';

const ReturnsDefaultTable = () => {
	const { getAccessTokenSilently } = useAuth0();
	const [
		data,
		setData
	] = useState([]);

	useEffect(
		() => {
			(async () => {
				try {
					const token = await getAccessTokenSilently();
					const response = await fetch(
						`${process.env.REACT_APP_API_BASE_URL}/percentage-returns`,
						{
							headers: {
								Authorization: `Bearer ${token}`
							}
						}
					);

					var data = await response.json();
					setData(data);
				} catch (e) {
					console.error(e);
				}
			})();
		},
		[
			getAccessTokenSilently
		]
	);

	const columns = [
		{ name: 'index', title: '' },
		{ name: 'oneYr', title: '1 Yr' },
		{ name: 'threeYr', title: '3 Yr' },
		{ name: 'fiveYr', title: '5 Yr' },
		{ name: 'tenYr', title: '10 Yr' },
		{ name: 'twentyYr', title: '20 Yr' },
		{ name: 'thirtyYr', title: '30 Yr' },
		{ name: 'all_time', title: 'All Time' },
		{ name: 'risk', title: 'Risk' },
		{
			name: 'worst_five',
			title: (
				<span>
					Worst <br /> 5 Yrs
				</span>
			)
		}
	];

	const toOneDecimal = (num) => parseFloat(num).toFixed(1);
	const rows = data.map((item) => {
		const dashStyle = {
			color: getIndexInfo(item.index).color,
			fontWeight: 'bold',
			fontSize: 14
		};
		const nameStyle = {
			paddingLeft: 5
		};

		return {
			index: (
				<div>
					<span style={dashStyle}>&mdash;</span>
					<span style={nameStyle}>{getIndexInfo(item.index).friendlyName}</span>
				</div>
			),
			oneYr: toOneDecimal(item.oneYr),
			threeYr: toOneDecimal(item.threeYr),
			fiveYr: toOneDecimal(item.fiveYr),
			tenYr: toOneDecimal(item.tenYr),
			twentyYr: toOneDecimal(item.twentyYr),
			thirtyYr: toOneDecimal(item.thirtyYr),
			all_time: toOneDecimal(item.all_time),
			risk: toOneDecimal(item.risk),
			worst_five: toOneDecimal(item.worst_five)
		};
	});

	if (data.length === 0) {
		return <div>Loading Returns table...</div>;
	}

	return (
		<div style={{ width: '100%', minWidth: '300px', backgroundColor: '#fff' }}>
			<StandardTable columns={columns} rows={rows} />
		</div>
	);
};

export default ReturnsDefaultTable;
