import React from 'react';
import '../../../css/Card/PageContentCard.css';

const PageContentCardFooter = ({ date }) => {
	return (
		<div className="pagecontent-card-footer-container ">
			<p className="last-updated-date-text">
				Data current as of <span>{date}</span>
			</p>
		</div>
	);
};

export default PageContentCardFooter;
