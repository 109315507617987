import React, { useContext } from 'react';
import '../../css/WorldEventsMenu/WorldEventsMenu.css';
import xIcon from '../../images/x_icon.svg';
import { EventContext } from '../../context/EventContext';

const WorldEventsMenu = ({ onClose }) => {
	const { checkedItems, setCheckedItems } = useContext(EventContext);

const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevState) => ({
        ...prevState,
        [id]: checked
    }));
};

	return (
		<div className="world-events-menu">
			<div className="world-events-menu-header">
				<h2>Events</h2>
				<div className="close-button" onClick={onClose}>
					<img src={xIcon} alt="Close" className="morningstar-logo" />
				</div>
			</div>
			<div className="world-events-menu-container">
				<div className={`world-events-menu-filter ${checkedItems.allEvents ? 'selected' : ''}`}>
					<span className="filter-title">All Events</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="allEvents"
						checked={checkedItems.allEvents}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="allEvents" className={`custom-checkbox ${checkedItems.allEvents ? 'checked' : 'unchecked'}`} />
				</div>
				<div className={`world-events-menu-filter ${checkedItems.warConflict ? 'selected' : ''}`}>
					<span className="filter-title">War & Conflict</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="warConflict"
						checked={checkedItems.warConflict}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="warConflict" className={`custom-checkbox ${checkedItems.warConflict ? 'checked' : 'unchecked'}`} />
				</div>
				<div className={`world-events-menu-filter ${checkedItems.fiscalEconomic ? 'selected' : ''}`}>
					<span className="filter-title">Fiscal & Economic</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="fiscalEconomic"
						checked={checkedItems.fiscalEconomic}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="fiscalEconomic" className={`custom-checkbox ${checkedItems.fiscalEconomic ? 'checked' : 'unchecked'}`} />
				</div>
				<div className={`world-events-menu-filter ${checkedItems.worldGeopolitical ? 'selected' : ''}`}>
					<span className="filter-title">World & Geopolitical</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="worldGeopolitical"
						checked={checkedItems.worldGeopolitical}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="worldGeopolitical" className={`custom-checkbox ${checkedItems.worldGeopolitical ? 'checked' : 'unchecked'}`} />
				</div>
				<div className={`world-events-menu-filter ${checkedItems.congressionalGovernment ? 'selected' : ''}`}>
					<span className="filter-title">Congressional & Government</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="congressionalGovernment"
						checked={checkedItems.congressionalGovernment}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="congressionalGovernment" className={`custom-checkbox ${checkedItems.congressionalGovernment ? 'checked' : 'unchecked'}`} />
				</div>
				<div className={`world-events-menu-filter ${checkedItems.scienceTechnology ? 'selected' : ''}`}>
					<span className="filter-title">Science & Technology</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="scienceTechnology"
						checked={checkedItems.scienceTechnology}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="scienceTechnology" className={`custom-checkbox ${checkedItems.scienceTechnology ? 'checked' : 'unchecked'}`} />
				</div>
				<div className={`world-events-menu-filter ${checkedItems.naturalGeological ? 'selected' : ''}`}>
					<span className="filter-title">Natural & Geological</span>
					<input
						type="checkbox"
						className="filter-checkbox"
						id="naturalGeological"
						checked={checkedItems.naturalGeological}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="naturalGeological" className={`custom-checkbox ${checkedItems.naturalGeological ? 'checked' : 'unchecked'}`} />
				</div>
			</div>
		</div>
	);
};

export default WorldEventsMenu;
