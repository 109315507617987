import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ChartOverlayWrapper from './ChartOverlayWrapper';

function RecessionsChart({ selectedButton }) {
	const { getAccessTokenSilently } = useAuth0();
	const [data, setData] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const token = await getAccessTokenSilently();
				const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/recessions`, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				});
				setData(await response.json());
			} catch (e) {
				console.error(e);
			}
		})();
	}, [getAccessTokenSilently]);

	if (!data || data.length === 0) {
		return <div>Loading Recessions chart...</div>;
	}

	const xAxisSettings = {
		type: 'date',
		range: [
			'1926-01-01',
			'2024-05-31'
		],
		dtick: 'M24',
		tickformat: '%y',
		tickmode: 'linear',
		side: 'bottom',
		showgrid: true,
	};

	const yAxisSettings =
	{
		range: [
			0,
			1
		],
		showticklabels: false,
		title: '',
		showgrid: false,
	};

	const chartData = [
		{
			x: data.flatMap((range) => [range.start_date, range.end_date, null]),
			y: data.flatMap(() => [0, 1, null]),
			fill: 'toself',
			fillcolor: '#e0e0e0',
			opacity: 0.5,
			mode: 'none',
			name: 'Canadian<br>Recession<br>(vertical bars)',
			yaxis: 'y',
			xaxis: 'x',
			legend: 'legend',
			showlegend: selectedButton === 'Events' ? false : true,
		}
	]

	const shapes = data.map((range) => ({
		type: 'rect',
		xref: 'x',
		yref: 'paper',
		x0: range.start_date,
		x1: range.end_date,
		y0: 0,
		y1: 1,
		fillcolor: '#e0e0e0',
		opacity: 0.5,
		line: {
			width: 0
		}
	}))

	return (
		<div style={{ width: '100%', minWidth: '300px' }}>
			<ChartOverlayWrapper
				singleChartData={chartData} 
				singleChartXAxisSettings={xAxisSettings} 
				singleChartYAxisSettings={yAxisSettings} 
				singleChartShapes={shapes} 
				selectedButton={selectedButton}
			/>
		</div>
	);
}

export default React.memo(RecessionsChart); // Memo to keep charts from redrawing when exporting image
