const indexInfoMap = {
    FOUSA05XQ2: { friendlyName: 'U.S. Small Stock Total Return Index', color: '#1268B3' },
    FOUSA07Y2R: { friendlyName: 'U.S. Large Stock Total Return Index', color: '#8CA2D3' },
    FOUSA05XQ4: { friendlyName: '20-Year Government Bonds', color: '#EE7623' },
    FOUSA05XQ9: { friendlyName: '5-Year Government Bonds', color: '#F39B5D' },
    FOUSA05XTS: { friendlyName: '30-Day Treasury Bills', color: '#658D3C' },
    CPIAUCNS: { friendlyName: 'Inflation', color: '#808285' },
    Balanced_Portfolio: { friendlyName: 'Balanced Portfolio', color: '#F3C317' },
    Growth_Portfolio: { friendlyName: 'Growth Portfolio', color: '#FFE045' },
    Income_Portfolio: { friendlyName: 'Income Portfolio', color: '#DBB015' },
    Global_Portfolio: { friendlyName: 'Global Portfolio', color: 'gray' },
    XIUSA000RX: { friendlyName: 'World ex-U.S. Stocks', color: 'red' },
    FOUSA06R6S: { friendlyName: 'Gold', color: 'gold' },
    XIUSA04F2P: { friendlyName: 'Emerging Markets Stocks', color: 'blue' },
};

/**
 * @typedef {Object} IndexInfo
 * @property {string} friendlyName - The friendly name of the index.
 * @property {string} color - The color associated with the index.
 */
/**
 * Retrieves the friendly name and color for a given index name.
 * 
 * @param {string} indexName - The name of the index.
 * @returns {IndexInfo} An object containing the friendly name and color of the index.
 * @throws {Error} If the index name is not found.
 */
export function getIndexInfo(indexName) {
    const info = indexInfoMap[indexName];

    if (!info) {
        throw new Error(`Index name "${indexName}" not found.`);
    }

    return info;
}

export function getSbbiTraceColor(metric) {
    switch (metric) {
        case 'U.S. Small Stock Total Return Index':
        case 'FOUSA05XQ2':
            return '#1268B3';
        case 'U.S. Large Stock Total Return Index':
        case 'FOUSA07Y2R':
            return '#8CA2D3';
        case 'SP/TSX Composite':
            return '#AC1D37';
        case 'Balanced Portfolio':
        case 'Balanced_Portfolio':
            return '#F3C317';
        case '20-Year Government Bonds':
        case 'FOUSA05XQ4':
            return '#EE7623';
        case '5-Year Government Bonds':
        case 'FOUSA05XQ9':
            return '#F39B5D';
        case '30-Day Treasury Bills':
        case 'FOUSA05XTS':
            return '#658D3C';
        case 'Inflation\r':
        case 'CPIAUCNS':
            return '#808285';
        case 'Growth_Portfolio':
            return '#FFE045';
        case 'Income_Portfolio':
            return '#DBB015';
        default:
            return '#' + Math.random().toString(16).slice(-6);
    }
} 

export function getSbbiTraceColorTransparent(metric) {
    switch (metric) {
        case 'U.S. Small Stock Total Return Index':
        case 'FOUSA05XQ2':
            return 'rgba(18, 104, 179, 0.3)';  // #1268B3 in rgba with 30% opacity
        case 'U.S. Large Stock Total Return Index':
        case 'FOUSA07Y2R':
            return 'rgba(140, 162, 211, 0.3)'; // #8CA2D3 in rgba with 30% opacity
        case 'SP/TSX Composite':
            return 'rgba(172, 29, 55, 0.3)';   // #AC1D37 in rgba with 30% opacity
        case 'Balanced Portfolio':
        case 'Balanced_Portfolio':
            return 'rgba(243, 195, 23, 0.3)';  // #F3C317 in rgba with 30% opacity
        case '20-Year Government Bonds':
        case 'FOUSA05XQ4':
            return 'rgba(238, 118, 35, 0.3)';  // #EE7623 in rgba with 30% opacity
        case '5-Year Government Bonds':
        case 'FOUSA05XQ9':
            return 'rgba(243, 155, 93, 0.3)';  // #F39B5D in rgba with 30% opacity
        case '30-Day Treasury Bills':
        case 'FOUSA05XTS':
            return 'rgba(101, 141, 60, 0.3)';  // #658D3C in rgba with 30% opacity
        case 'Inflation\r':
        case 'CPIAUCNS':
            return 'rgba(128, 130, 133, 0.3)'; // #808285 in rgba with 30% opacity
        case 'Growth_Portfolio':
            return 'rgba(255, 224, 69, 0.3)';  // #FFE045 in rgba with 30% opacity
        case 'Income_Portfolio':
            return 'rgba(219, 176, 21, 0.3)';  // #DBB015 in rgba with 30% opacity
        default:
            // Generate random semi-transparent color
            return 'rgba(' + Math.floor(Math.random() * 256) + ',' +
                            Math.floor(Math.random() * 256) + ',' +
                            Math.floor(Math.random() * 256) + ', 0.3)';
    }
}

export function getSbbiTraceCommonName(metric) {
    switch (metric) {
        case 'U.S. Small Stock Total Return Index':
        case 'FOUSA05XQ2':
            return 'U.S. Small Stocks';
        case 'U.S. Large Stock Total Return Index':
        case 'FOUSA07Y2R':
            return 'U.S. Large Stocks';
        case 'SP/TSX Composite':
            return 'SP/TSX Composite';
        case 'Balanced Portfolio':
        case 'Balanced_Portfolio':
            return 'Balanced Portfolio';
        case '20-Year Government Bonds':
        case 'FOUSA05XQ4':
            return '20Y Gov Bonds';
        case '5-Year Government Bonds':
        case 'FOUSA05XQ9':
            return '5Y Gov Bonds';
        case '30-Day Treasury Bills':
        case 'FOUSA05XTS':
            return '30d T-Bills';
        case 'Inflation\r':
        case 'CPIAUCNS':
            return 'Inflation';
        case 'Growth_Portfolio':
            return 'Growth Portfolio';
        case 'Income_Portfolio':
            return 'Income Portfolio';
        default:
            return '????????????';
    }
}

export function dollarsWithCommas(number) {
    //console.log("dwc");
    return Math.floor(number).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

export function calculateContinuousCompoundingAPR(P0, Pn, n) {
    // Calculate r for continuous compounding
    const r = Math.log(Pn / P0) / n;

    // Convert to percentage
    const apr = r * 100;

    return apr.toFixed(1); // Return APR formatted to two decimal places
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function debounce(func){
    var timer;
    return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func,100,event);
    };
  }
