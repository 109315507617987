import React from 'react';
import morningstarLogo from '../../images/morningstar_logo_svg.svg';
import '../../css/Footer/Footer.css';

const Footer = () => {
	return (
		<div className="footer">
			<div className="disclaimer-section">
				<b>Past performance is no guarantee of future results.</b> Hypothetical
				value of $100 invested at the beginning of 1950. Assumes reinvestment of
				all income and no transaction costs or taxes. This is for illustrative
				purposes only and not indicative of any investment. An investment cannot
				be made directly in an index. Government bonds and Treasury bills are
				guaranteed by the full faith and credit of the Canadian government as to
				the timely payment of principal and interest, while stocks are not
				guaranteed and have been more volatile than the other asset classes.
				Furthermore, small stocks are more volatile than large stocks and are
				subject to signiﬁcant price ﬂuctuations, business risks, and are thinly
				traded. International investments involve special risks such as
				ﬂuctuations in currency, foreign taxation, economic and political risks,
				liquidity risks, and differences in accounting and ﬁnancial standards.
				Canadian recessions are deﬁned as two or more consecutive quarters of
				negative GDP, while U.S. recession data is from the National Bureau of
				Economic Research (NBER). Gold prices are from London Bullion Market
				Association and represent the London P.M. daily closing prices per troy
				ounce. Oil prices are for West Texas Intermediate Crude per barrel from
				Morningstar. Gold and oil prices quoted in U.S. dollars. The balanced
				portfolio was created for illustrative purposes only. It is neither a
				recommendation, nor an actual portfolio. All income was reinvested and
				the portfolio was rebalanced every month. Returns are compound annual
				returns, and risk is calculated as the standard deviation of
				calendar-year returns. The worst 5-year calculations are out of 823 (583
				for World Markets ex U.S. stocks) rolling 60-month periods. Source: U.S.
				Small Stocks—Ibbotson® Small Company Stock index; World Markets ex
				U.S.—Morgan Stanley Capital International (MSCI) World ex U.S. index;
				U.S. Large Stocks—Standard and Poor’s 90® index from 1950 through
				February 1957 and the S&P 500 index thereafter, which is an unmanaged
				group of securities and considered to be representative of the U.S.
				stock market in general; S&P/TSX Composite—Canadian Financial Markets
				Research Center for 1950–1955 and Standard and Poor’s/TSX Composite
				index total return series thereafter, which replaced the TSE300 Total
				Return index on May 1, 2002; FTSE Canada Long Bond Index—Morningstar
				Direct; 5 Year Guaranteed Investment Certiﬁcates—Average 5-Year GIC
				(Bond) from Morningstar Direct; 91 Day Canada Treasury Bills—FTSE Canada
				Treasury Bill 91 Day index from Morningstar Direct; Consumer Price
				Index—Statistics Canada; Gross Domestic Product—Bank of Canada for 1950
				to Q1 1981 and Statistics Canada thereafter. The second-quarter 2023 GDP
				value is an estimate from the Bank of Canada; Canadian Dollar in U.S.
				Dollars—Bank of Canada; Prime Rate—Bank of Canada; Government of Canada
				Long Term Bond Yield—Bank of Canada.<b>
					©2023 Precision Information LLC, dba Financial Fitness Group (FFG).
				</b>
				All Rights Reserved. <b>The reproduction of part or all of this chart
				without prior written consent from FFG is prohibited.</b>
			</div>
			<div className="morningstar-container">
				<img
					src={morningstarLogo}
					alt="Morningstar Logo"
					className="morningstar-logo"
				/>
			</div>
		</div>
	);
};

export default Footer;
