import React, { useState } from 'react';
import '../../css/SideMenus/SideMenu.css';

import educatedInvestorLogo from '../../images/_logo_educated_investor.svg';
import andexChartIcon from '../../images/sideMenu/andex-chart-icon.svg';
import andexChartIconBlack from '../../images/sideMenu/andex-chart-icon-black.svg';
import chartCandleIcon from '../../images/sideMenu/tabler_chart-candle.svg';
import chartPercentageIcon from '../../images/sideMenu/tabler_percentage.svg';
import chartCalendarIcon from '../../images/sideMenu/tabler_calendar-repeat.svg';
import chartArrowIcon from '../../images/sideMenu/arrow_drop_down.svg';

const SideMenu = ({ selectedSideMenuItem, onSideMenuItemSelected }) => {
	const [
		submenuVisible,
		setSubmenuVisible
	] = useState(true);

	const toggleSubmenu = () => {
		setSubmenuVisible(!submenuVisible);
	};

	const handleItemClick = (itemName) => {
		onSideMenuItemSelected(itemName);
	};

	return (
		<div className="sidebar">
			<div className="sidebar-section">
				<div className="logo-container">
					<img
						src={educatedInvestorLogo}
						alt="Educated Investor Logo"
						className="main-logo"
					/>
				</div>
				<div className="menu-title-container" onClick={toggleSubmenu}>
					<img
						src={andexChartIcon}
						style={{ color: '#000' }}
						alt="Rolling Periods"
					/>
					<p className="menu-title">Andex Chart</p>
					<img
						src={chartArrowIcon}
						alt="Arrow"
						className={`arrow-icon ${submenuVisible ? 'expanded' : ''}`}
					/>
				</div>
				<div
					className={`submenu-container ${submenuVisible ? 'expanded' : ''}`}
				>
					{/* <div className="menu-item-container">
						<img src={chartCandleIcon} alt="Rolling Period" />
						<p className="menu-item">Rolling Period</p>
					</div> */}
					<div
						className="menu-item-container"
						onClick={() => handleItemClick('Full Andex')}
					>
						<img src={andexChartIconBlack} alt="" />
						<p
							className={`menu-item ${selectedSideMenuItem === 'Full Andex'
								? 'selected'
								: ''}`}
						>
							Full Andex
						</p>
					</div>
					<div
						className="menu-item-container"
						onClick={() => handleItemClick('Percentage returns')}
					>
						<img src={chartPercentageIcon} alt="" />
						<p
							className={`menu-item ${selectedSideMenuItem ===
							'Percentage returns'
								? 'selected'
								: ''}`}
						>
							Percentage returns
						</p>
					</div>
					<div
						className="menu-item-container"
						onClick={() => handleItemClick('Past 30 years')}
					>
						<img src={chartCalendarIcon} alt="" />
						<p
							className={`menu-item ${selectedSideMenuItem === 'Past 30 years'
								? 'selected'
								: ''}`}
						>
							Past 30 years
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideMenu;
