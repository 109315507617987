import { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { capitalizeFirstLetter } from '../helpers';

export const CustomizeContext = createContext();

export const CustomizeProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    let firstPortfolioType = '';
    const [portfolioDataInitial, setPortfolioDataInitial] = useState();

    const [customizeSettings, setCustomizeSettings] = useState({
        'isCustomizeMenuVisible': false,
        'amountInvested': null,
        'chartTypes': null,
        'portfolioData': {},
        'activePortfolioType': null,
        'activeTab': null,
        'percentageState': null
    });

    useEffect(() => {
        const getPortfolioData = async () => {
            const token = await getAccessTokenSilently();
            const portfoliosResponse = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/portfolios`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const portfolioResp = await portfoliosResponse.json();

            let portfolioAllocation = {};

            portfolioResp.forEach(({ portfolio_type, common_name, percentage }) => {
                // Normalize common_name to fit the keys of the portfolio allocation
                let key = common_name.toLowerCase().replace(/ /g, ''); // Normalize to lowercase and remove spaces
                if (key.includes("ltgovernmentbonds")) {
                    key = "bonds"; // Map "LT Government Bonds" to "bonds"
                } else if (key.includes("treasurybills")) {
                    key = "cash"; // Map "Treasury Bills" to "cash"
                } else if (key.includes("smallstocks")) {
                    key = "smallStocks"; // Map "World ex-U.S. Stocks" to "other"
                } else if (key.includes('largestocks')) {
                    key = 'largeStocks'
                } else {
                    key = "other"; // Default to "stocks" if not mapped
                }

                // Ensure the portfolio type exists before updating it
                if (!portfolioAllocation[portfolio_type]) {
                    portfolioAllocation[portfolio_type] = { 
                        smallStocks: 0, 
                        largeStocks: 0, 
                        bonds: 0, 
                        cash: 0, 
                        other: 0 
                    };
                }
                portfolioAllocation[portfolio_type][key] += percentage * 100;
            });
            setPortfolioDataInitial(portfolioAllocation);
        }
        getPortfolioData()
    }, [])

    useEffect(() => {
        const amountInvested = getAmountInvested();
        const chartTypes = getChartTypes();
        const portfolioData = portfolioDataInitial
        const activeTab = 'Settings';
        
        if (portfolioData && Object.keys(portfolioData).length > 0) {
            firstPortfolioType = Object.keys(portfolioData)[0].charAt(0).toUpperCase() + Object.keys(portfolioData)[0].slice(1);
        }

        setCustomizeSettings((prev) => ({
            ...prev,
            'chartTypes': chartTypes,
            'activePortfolioType': capitalizeFirstLetter(firstPortfolioType),
            'portfolioData': portfolioData,
            'amountInvested': amountInvested,
            'activeTab': activeTab,
            'percentageState': 'good'
        }));
    }, [portfolioDataInitial]);

    const setIsCustomizeMenuVisible = (isVisible) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'isCustomizeMenuVisible': isVisible,
        }));
    };

    const setAmountInvested = (newAmountInvested) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'amountInvested': newAmountInvested,
        }));
    };

    const setActivePortfolioType = (portfolioType) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'activePortfolioType': capitalizeFirstLetter(portfolioType),
        }));
    };

    const setPortfolioData = (newPortfolioData) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'portfolioData': {
                ...prev.portfolioData,
                [prev.activePortfolioType.toLowerCase()]: newPortfolioData
            }
        }));
    };    

    const setPercentageState = (state) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'percentageState': state,
        }));
    };

    const setChartTypes = (chartTypes) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'chartTypes': chartTypes,
        }));
    };

    const setActiveTab = (tab) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'activeTab': tab,
        }));
    };

    const getAmountInvested = () => {
        return 1;
    }

    const getChartTypes = () => {
        return {
            All: true,
            SBBI: true,
            Expansions: true,
            Recessions: true,
            Commodities: true,
            InterestVsInflation: true,
            TaxRate: true,
            Government: true,
            SP500: true,
        }
    }

    return (
        <CustomizeContext.Provider
            value={
                {
                    customizeSettings,
                    setIsCustomizeMenuVisible,
                    setActivePortfolioType,
                    setAmountInvested,
                    setActiveTab,
                    setChartTypes,
                    setPercentageState,
                    setPortfolioData,
                }
            }
        >
            {children}
        </CustomizeContext.Provider>
    );
}