import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Plot from 'react-plotly.js';

const PortfolioChart = ({ assetMix }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/portfolios`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setData(await response.json());
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  if (!data || data.length === 0) {
    return <div>Loading Portfolio chart...</div>;
  }

  let chartData = [];
  let filteredData = null;
  let chartLayout = {};

  switch (assetMix) {
    case 'balanced':
      filteredData = data.filter(item => item.portfolio_type === "balanced");
      chartData = [{
        values: filteredData.map(item => item.percentage),
        labels: filteredData.map(item => item.common_name),
        type: 'pie',
        hole: 0.4
      }];
      chartLayout = {
        title: 'Balanced Portfolio'
      };
      break;
    case 'growth':
      filteredData = data.filter(item => item.portfolio_type === "growth");
      chartData = [{
        values: filteredData.map(item => item.percentage),
        labels: filteredData.map(item => item.common_name),
        type: 'pie',
        hole: 0.4
      }];
      chartLayout = {
        title: 'Growth Portfolio'
      };
      break;
    case 'income':
      filteredData = data.filter(item => item.portfolio_type === "income");
      chartData = [{
        values: filteredData.map(item => item.percentage),
        labels: filteredData.map(item => item.common_name),
        type: 'pie',
        hole: 0.4
      }];
      chartLayout = {
        title: 'Income Portfolio'
      };
      break;
    case 'global':
      filteredData = data.filter(item => item.portfolio_type === "global");
      chartData = [{
        values: filteredData.map(item => item.percentage),
        labels: filteredData.map(item => item.common_name),
        type: 'pie',
        hole: 0.4
      }];
      chartLayout = {
        title: 'Global Portfolio',
      };
      break;
    default:
      console.error('Unknown asset mix');
  }

  return (
    <div style={{ width: '100%', minWidth: '300px' }}>
      <Plot
        divId='PlotlyGraph'
        data={chartData}
        layout={chartLayout}
        style={{ width: '100%', height: '100%' }}
        config={{ displayModeBar: false, useResizeHandler: true }}
      />
    </div>
  );
};

export default React.memo(PortfolioChart); // Memo to keep charts from redrawing when exporting image
