import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import { useContext } from 'react';
import ChartsCheckbox from './ChartsCheckbox';
import { CustomizeContext } from '../../../context/CustomizeContext';

const ChartsTab = () => {
  const { 
    customizeSettings,
    setChartTypes
	} = useContext(CustomizeContext);

  const toggleChartSelection = (key) => {
    if (key === 'All') {
      const allSelected = !customizeSettings.chartTypes.All;
      const newSelection = Object.keys(customizeSettings.chartTypes).reduce((acc, curr) => {
        acc[curr] = allSelected;
        return acc;
      }, {});
      setChartTypes(newSelection);
    } else {
      setChartTypes({
        ...customizeSettings.chartTypes,
        [key]: !customizeSettings.chartTypes[key],
        All: false,
      });
    }
  };

  return (
    <div className='tab-content'>
      <div className='chart-options'>
        <div className={`chart-item font-weight-400 ${customizeSettings.chartTypes.All ? 'selected' : ''}`}>
          <ChartsCheckbox
            label='All'
            type='checkbox'
            id='All'
            checked={customizeSettings.chartTypes.All}
            onChange={() => toggleChartSelection('All')}
          />
        </div>
        {['SBBI', 'Expansions', 'Recessions', 'Commodities', 'InterestVsInflation', 'TaxRate', 'Government', 'SP500'].map((chartKey, index) => (
          <div className={`chart-item ${customizeSettings.chartTypes[chartKey] ? 'selected' : ''}`} key={index}>
            <ChartsCheckbox
              label={chartKey === 'SBBI' ? 'SBBI' :
                chartKey === 'Expansions' ? 'Expansions/Contractions/Recoveries' :
                  chartKey === 'Recessions' ? 'Recessions' :
                    chartKey === 'Commodities' ? 'Commodities' :
                      chartKey === 'InterestVsInflation' ? 'Prime Interest Rate vs Inflation %' :
                        chartKey === 'TaxRate' ? 'Top Federal Tax Rate' :
                          chartKey === 'Government' ? 'President/House/Senate' :
                            'S&P 500 (PE Ratio)'}
              type='checkbox'
              id={chartKey}
              checked={customizeSettings.chartTypes[chartKey]}
              onChange={() => toggleChartSelection(chartKey)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
export default ChartsTab