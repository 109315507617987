import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Plot from 'react-plotly.js';
import ChartOverlayWrapper from './ChartOverlayWrapper';

const CommoditiesChart = () => {
	const { getAccessTokenSilently } = useAuth0();
	const [
		data,
		setData
	] = useState([]);

	useEffect(
		() => {
			(async () => {
				try {
					const token = await getAccessTokenSilently();
					const response = await fetch(
						`${process.env.REACT_APP_API_BASE_URL}/commodities`,
						{
							headers: {
								Authorization: `Bearer ${token}`
							}
						}
					);
					setData(await response.json());
				} catch (e) {
					console.error(e);
				}
			})();
		},
		[
			getAccessTokenSilently
		]
	);

	if (!data || data.length === 0) {
		return <div>Loading Commodities chart...</div>;
	}

	const trace1 = {
		name: 'Oil',
		x: data.map((e) => e.Date),
		y: data.map((e) => e.Oil),
		type: 'scatter',
		marker: {
			color: '#414042'
		},
		yaxis: 'y2',
	};

	const trace2 = {
		name: 'Gold',
		x: data.map((e) => e.Date),
		y: data.map((e) => e.Gold),
		type: 'scatter',
		marker: {
			color: '#F3C317'
		},
		yaxis: 'y3',
	};

	const xAxisSettings = {
		type: 'date',
		range: [
			'1986-01-01',
			'2024-05-31'
		],
		dtick: 'M60',
		tickmode: 'linear',
		showgrid: true,
		side: 'bottom',
	};

	const yAxisSettings =
	{
		title: {
			text: 'Oil Price ($/barrel)',
			font: {
				size: 18,
				color: '#414042',
				weight: 'normal'
			}
		},
		tickfont: { color: 'black' }
	};

	const yAxis2Settings = 
	{
		title: {
			text: 'Gold Price ($/ounce)',
			font: {
				size: 18,
				color: '#414042',
				weight: 'bold'
			}
		},
		tickfont: { color: 'gold', weight: 'bold' },
		overlaying: 'y2',
		side: 'right'
	};

	const chartData = [
		trace1,
		trace2
	];

	return (
		<div style={{ width: '100%', minWidth: '300px' }}>
			<ChartOverlayWrapper
			 singleChartData={chartData} 
			 singleChartXAxisSettings={xAxisSettings}
			 singleChartYAxisSettings={yAxisSettings}
			 singleChartYAxis2Settings={yAxis2Settings}
			 />
		</div>
	);
};

export default React.memo(CommoditiesChart); // Memo to keep charts from redrawing when exporting image
