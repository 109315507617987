import { useState, useContext } from 'react';
import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import { ReactComponent as ChevronIcon } from '../../../images/chevron-icon.svg';
import { CustomizeContext } from '../../../context/CustomizeContext';
import { capitalizeFirstLetter } from '../../../helpers';

const PortfolioDropdown = () => {
  const {
    customizeSettings,
    setActivePortfolioType
  } = useContext(CustomizeContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setActivePortfolioType(option);
    setIsOpen(false);
  };

  return (
    <div className="portfolio-dropdown-container">
      <label htmlFor="portfolioType">Portfolio type</label>
      <div className="dropdown">
        <div id='portfolioType' className="dropdown-header" onClick={handleDropdownClick}>
          {customizeSettings.activePortfolioType}
          <span className={`chevron ${isOpen ? 'rotate' : ''}`}><ChevronIcon /></span>
        </div>
        {isOpen && (
          <ul className="dropdown-options">
            {Object.keys(customizeSettings.portfolioData).map((option) => (
              <li
                key={option}
                className={`dropdown-option ${customizeSettings.activePortfolioType === option ? 'selected' : ''
                  }`}
                onClick={() => handleOptionClick(option)}
              >
                <span className="option-text">{capitalizeFirstLetter(option)}</span>
                <span className={`option-icon ${customizeSettings.activePortfolioType === capitalizeFirstLetter(option) ? 'filled' : 'outlined'}`}></span>
              </li>
            ))}

          </ul>
        )}
      </div>
    </div>
  );
};

export default PortfolioDropdown;
