import React from 'react';
import '../../../css/Card/PageContentCard.css';
import presentationIcon from '../../../images/presentation_icon.svg';
import presentationIconOn from '../../../images/presentation_icon_on.svg';
import exportIcon from '../../../images/export_icon.svg';

const PageContentCardHeader = ({
    title,
    selectedSideMenuItem,
    togglePresentationMode,
    isPresetationModeOn,
    exportChart,
    date,
}) => {

    const monthYearDate = date.replace(/^\d{1,2}\s/, "");

    return (
        <div className="pagecontent-card-header-container">
            <p className="pagecontent-card-title">{title}</p>
            <div className="pagecontent-card-subtitle-container">
                <p className="pagecontent-card-subtitle">
                    {selectedSideMenuItem === 'Full Andex' ? `$1 • January 1926 - ${monthYearDate} • All Assets` : ''}
                    {selectedSideMenuItem === 'Percentage returns' ? `$1 • January 1926 - ${monthYearDate} • All Assets` : ''}
                    {selectedSideMenuItem === 'Past 30 years' ? `$10,000 • January 1994 - ${monthYearDate} • All Assets` : ''}
                </p>
                <img
                    src={isPresetationModeOn ? presentationIconOn : presentationIcon}
                    alt="Presentation Icon"
                    className="presentation-icon"
                    onClick={togglePresentationMode}
                />
                <img
                    src={exportIcon}
                    alt="Export Chart Icon"
                    className="export-icon"
                    onClick={exportChart}
                />
            </div>
        </div>
    );
};

export default PageContentCardHeader;
