import React from 'react';
import '../../css/Buttons/ChartTypeButton.css';

const ChartTypeButton = ({ label, selected, onClick }) => {
	return (
		<button
			className={`chart-type-button ${selected ? 'selected' : ''}`}
			onClick={onClick}
		>
			{label}
		</button>
	);
};

export default ChartTypeButton;
