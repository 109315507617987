import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import { EventContext } from '../../context/EventContext';
import {
  dollarsWithCommas,
  getSbbiTraceColor,
  getSbbiTraceCommonName,
  calculateContinuousCompoundingAPR,
  getSbbiTraceColorTransparent,
} from '../../helpers';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ChartOverlayWrapper = ({ 
  singleChartData, 
  singleChartYAxisSettings,
  singleChartYAxis2Settings, 
  singleChartXAxisSettings, 
  singleChartShapes, 
  singleChartTitle,
  selectedButton
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { checkedItems } = useContext(EventContext);
  const [
    sbbi,
    setSbbi
  ] = useState([]);
  const [
    loadingSbbi,
    setLoadingSbbi
  ] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [historicalEvents, setHistoricalEvents] = useState([]);

  const chartData = [];

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          const sbbiResponse = await fetch(`${API_BASE_URL}/sbbi-default`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setSbbi(await sbbiResponse.json());
          setLoadingSbbi(false);

          const eventsResponse = await fetch(`${API_BASE_URL}/historical-events`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setHistoricalEvents(await eventsResponse.json());
          setLoadingEvents(false);
        } catch (e) {
          console.error(e);
          setLoadingSbbi(false);
          setLoadingEvents(false);
        }
      };
      fetchData();
    },
    [
      getAccessTokenSilently
    ]
  );

  const filteredEvents = useMemo(() => {
    if (!historicalEvents || historicalEvents.length === 0) return [];

    return historicalEvents.filter(event => {
      if (checkedItems.allEvents) {
        return true;
      } else {
        return (
          (checkedItems.warConflict && event.Type === 'Wars/Conflicts') ||
          (checkedItems.fiscalEconomic && event.Type === 'Fiscal/Economic') ||
          (checkedItems.worldGeopolitical && event.Type === 'World/Geopolitical') ||
          (checkedItems.congressionalGovernment && event.Type === 'Congressional/Government') ||
          (checkedItems.scienceTechnology && event.Type === 'Science/Technology') ||
          (checkedItems.naturalGeological && event.Type === 'Natural/Geological')
        );
      }
    });
  }, [historicalEvents, checkedItems]);

  const annotations = useMemo(
    () => {
      const BAND_RANGE = [
        0,
        4.8
      ];
      const BAND_INCREMENT = 0.2;
      let yBand = 0;
      return _(filteredEvents)
        .orderBy('Date')
        .map((e) => {
          yBand += BAND_INCREMENT;
          yBand = yBand > BAND_RANGE[1] ? BAND_RANGE[0] : yBand;
          let dynamicXAnchor = 'left';
          let dynamicAX = 4;
          if (e.Date > '2014-01-01') {
            dynamicXAnchor = 'right';
            dynamicAX = -4;
          }
          return {
            x: e.Date,
            y: yBand,
            text: e.Event,
            showarrow: true,
            arrowhead: 6,
            ax: dynamicAX,
            ay: 0,
            xanchor: dynamicXAnchor,
            font: { color: 'rgba(0, 0, 0, 0.6)', size: 10 },
            arrowcolor: 'rgba(0, 0, 0, 0.6)'
          };
        })
        .value();
    },
    [
      filteredEvents
    ]
  );

  if (
    loadingSbbi || loadingEvents
  ) {
    return <div>Loading Full Andex view...</div>;
  }

  if (!sbbi || sbbi.length === 0) {
    return <div>No data available for the SBBI (all-time) chart.</div>;
  }

  function generateCustomLegend(metric, sbbi) {
    const firstValue = sbbi[0][metric];
    const lastValue = sbbi.slice(-1)[0][metric];
    const color = getSbbiTraceColor(metric);
    const name = getSbbiTraceCommonName(metric);

    const apr = calculateContinuousCompoundingAPR(firstValue, lastValue, 98);

    const legendHTML = `<span style="color:${color}"><br />${name}<br />${dollarsWithCommas(
      lastValue
    )} (${apr}%)</span>`;

    return legendHTML;
  }

  // Get list of metrics (excluding 'Date')
  const sbbiMetrics = Object.keys(sbbi[0]).filter(
    (key) =>
      key !== 'Date' && key !== 'Growth_Portfolio' && key !== 'Income_Portfolio'
  );

  const sbbiTraces = sbbiMetrics.flatMap((metric) => {
    if (metric === 'CPIAUCNS') {
      // Separate data into above and below 1
      const aboveOne = sbbi
        .map((entry) => entry[metric])
        .map((val) => (val >= 1 ? val : null))
      const belowOne = sbbi
        .map((entry) => entry[metric])
        .map((val) => (val < 1 ? val : null));

      const hoverText = sbbi.map(
        (entry) =>
          getSbbiTraceCommonName(metric) +
          '<br />' +
          entry.Date.toString() +
          'x' +
          '<br />' +
          '$' +
          Math.floor(entry[metric])
      );

      const fullTrace = {
        name: generateCustomLegend(metric, sbbi),
        x: sbbi.map((entry) => entry.Date),
        y: sbbi.map((entry) => entry[metric]),
        xaxis: 'x1',
        yaxis: 'y1',
        type: 'scatter',
        mode: 'lines',
        showlegend: false,
        legendgroup: metric,
        line: { color: getSbbiTraceColorTransparent(metric) },
        hoverinfo: 'text',
        hovertext: hoverText
      };

      const oneTrace = {
        name: 'SBBI One',
        x: sbbi.map((entry) => entry.Date),
        y: sbbi.map((entry) => 1),
        xaxis: 'x1',
        yaxis: 'y1',
        type: 'scatter',
        mode: 'lines',
        showlegend: false,
        legendgroup: metric,
        line: { color: 'rgba(0, 0, 0, 0)' }
      };

      // Create traces for above 1, below 1, and fill up to 1
      const positiveTrace = {
        name: `${getSbbiTraceCommonName(metric)} (Positive)`,
        x: sbbi.map((entry) => entry.Date),
        y: aboveOne,
        xaxis: 'x1',
        yaxis: 'y1',
        type: 'scatter',
        mode: 'lines',
        line: { color: 'rgba(0, 0, 0, 0)' },
        fill: 'tonexty',
        fillcolor: 'rgba(150, 150, 150, 0.45)',
        showlegend: false,
        legendgroup: metric,
      };

      const negativeTrace = {
        name: `${getSbbiTraceCommonName(metric)} (Negative)`,
        x: sbbi.map((entry) => entry.Date),
        y: belowOne,
        xaxis: 'x1',
        yaxis: 'y1',
        type: 'scatter',
        mode: 'lines',
        line: { color: 'rgba(0, 0, 0, 0)' },
        fill: 'tonexty',
        fillcolor: 'rgba(150, 150, 150, 0.45)',
        showlegend: false,
        legendgroup: metric,
      };

      //Add oneTrace before each of the others, so "fill to next y" fills to one
      return [
        fullTrace,
        oneTrace,
        positiveTrace,
        oneTrace,
        negativeTrace
      ];
    } else {
      // For other metrics, create a single trace
      const hoverText = sbbi.map(
        (entry) =>
          getSbbiTraceCommonName(metric) +
          '<br />' +
          entry.Date +
          '<br />' +
          '$' +
          Math.floor(entry[metric])
      );

      return {
        name: generateCustomLegend(metric, sbbi),
        x: sbbi.map((entry) => entry.Date),
        y: sbbi.map((entry) => entry[metric]),
        xaxis: 'x1',
        yaxis: 'y1',
        type: 'scatter',
        mode: 'lines',
        line: { color: getSbbiTraceColorTransparent(metric) },
        fill: 'none',
        showlegend: false,
        hoverinfo: 'text',
        hovertext: hoverText
      };
    }
  });

  // Calculate final y-values and sort traces by highest final y-value to lowest
  // This assists the legend, that will spit out the traces in the order added to
  // the chart data. We want them ordered so they sort of line up with the ending
  // points of the traces.
  const sortedSbbiTraces = sbbiTraces.slice().sort((traceA, traceB) => {
    const showLegendA = traceA.showlegend === true;
    const showLegendB = traceB.showlegend === true;

    if (showLegendA && showLegendB) {
      // Sort legends with showlegend === true by final Y value
      const finalYValueA = findFinalYValue(traceA);
      const finalYValueB = findFinalYValue(traceB);
      return finalYValueB - finalYValueA; // Sort in ascending order
    } else if (showLegendA && !showLegendB) {
      // traceA should come before traceB
      return -1;
    } else if (!showLegendA && showLegendB) {
      // traceB should come before traceA
      return 1;
    } else {
      // Neither has showlegend === true, maintain original order
      return 0;
    }
  });

  function findFinalYValue(trace) {
    if (Array.isArray(trace.y)) {
      for (let i = trace.y.length - 1; i >= 0; i--) {
        if (trace.y[i] !== null && !isNaN(trace.y[i])) {
          return trace.y[i];
        }
      }
    }
    return -Infinity; // Return a very low value if no valid y-value found
  }

  chartData.push(...sortedSbbiTraces);
  chartData.push(...singleChartData);

  const sbbiYAxisTicks = {
    tickValues: [
      1,
      10,
      100,
      1000,
      10000,
      100000,
      1000000,
      10000000
    ],
    tickText: [
      '1',
      '10',
      '100',
      '1K',
      '10K',
      '100K',
      '1M',
      '10M'
    ]
  };

  const combinedLayout = {
    height: 500,
    title: singleChartTitle ||  '',
    // Ensure the layout is conducive to overlapping charts
    yaxis: selectedButton === 'Events' ? {
      domain: [1, 1],
      title: {
        text: 'Growth of $1 (with no acquisition costs or taxes and all income reinvested)',
        font: { size: 11 },
      },
      type: 'log',
      tickvals: sbbiYAxisTicks.tickValues,
      ticktext: sbbiYAxisTicks.tickText,
      ticklabelposition: 'outside',
    } : {
      domain: [1, 1],
      title: {
        //text: 'Growth of $1 (with no acquisition costs or taxes and all income reinvested)',
        font: { size: 11 },
      },
      type: 'log',
      showticklabels: false, // Turned off for now to keep focus on single chart. Commented out title
      showgrid: false,
    },
    yaxis2: singleChartYAxisSettings || [], // Add the xaxis or yaxis variables to the data traces for these
    yaxis3: singleChartYAxis2Settings || [],
    xaxis: singleChartXAxisSettings ? { ...singleChartXAxisSettings } : {
      type: 'date',
      range: [
        '1926-01-01',
        '2024-05-31'
      ],
      dtick: 'M120', // Display every 10 years
      tickmode: 'linear',
      showgrid: false,
      side: 'bottom',
      showticklabels: true,
    },
    shapes: singleChartShapes || [],
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    annotations: annotations,
    margin: selectedButton === 'Events' ? { r: 50, t: 25, b: 16, l: 50 } : {},
    legend: {
      orientation: 'v',
      xanchor: 'center',
      yanchor: 'bottom',
      y: 1.1,
  },
  };



  return (
    <div style={{ width: '100%', minWidth: '300px' }}>
      <Plot
        divId='PlotlyGraph'
        data={chartData}
        layout={combinedLayout}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        config={{ modeBarButtonsToRemove: ['toImage','lasso2d']}}
      />
    </div>
  );
};

export default ChartOverlayWrapper;
