import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';

const ChartsCheckbox = ({ label, type, id, placeholder, checked, onChange}) => {
  

  return (
    <div className='checkbox'>
      <label htmlFor={id} className='checkbox-label'>
        {label}
      </label>
      <input
        id={id}
        type='checkbox'
        placeholder={placeholder}
        checked={checked}
        onChange={onChange}
        className='checkbox-input'
      />
    </div>
  )
}
export default ChartsCheckbox;