import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import { useState, useEffect, useContext } from 'react';
import { ReactComponent as GoodCheckIcon } from '../../../images/circle-checked-icon.svg';
import { ReactComponent as AlertTriangleIcon } from '../../../images/alert-triangle-icon.svg';
import PortfolioDropdown from './PortfolioDropdown';
import { CustomizeContext } from '../../../context/CustomizeContext';

const PortfolioTab = () => {
  const { customizeSettings, setPercentageState, setPortfolioData } = useContext(CustomizeContext);

  const [allocation, setAllocation] = useState({}); 

  useEffect(() => {
    if (customizeSettings.portfolioData) {
      const newAllocation = customizeSettings.portfolioData[customizeSettings.activePortfolioType.toLowerCase()] || {};
      setAllocation(newAllocation);
    }
  }, [customizeSettings.activePortfolioType, customizeSettings.portfolioData]);

  const totalAllocation = Object.values(allocation).reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    if (totalAllocation < 100) {
      setPercentageState('warning');
    } else if (totalAllocation === 100) {
      setPercentageState('good');
    } else {
      setPercentageState('bad');
    }
  }, [totalAllocation]);

  const handleAllocationChange = (field, value) => {
    const newValue = Math.max(0, Math.min(100, parseFloat(value) || 0));
    const textboxText = newValue.toString().replace(/^0+/, '') || '0';

    setAllocation((prevAllocation) => ({
      ...prevAllocation,
      [field]: parseFloat(textboxText),
    }));
  };

  const updatePortfolio = () => {
    setPortfolioData(allocation);
  }

  const saveCustomPortfolio = () => {
    console.log('Custom portfolio saved!');
  }

  const isDisabled = customizeSettings.percentageState === 'warning' || customizeSettings.percentageState === 'bad';

  return (
    <div className='tab-content'>
      <PortfolioDropdown portfolioType={customizeSettings.activePortfolioType} />
      <div className={`allocation-control ${customizeSettings.percentageState}`}>
        {Object.keys(allocation).map((field, index) => (
          <div key={index} className='allocation-item font-weight-400'>
            <label htmlFor={field}>
              {field === 'smallStocks' ? 'Small Stocks' :
                field === 'largeStocks' ? 'Large Stocks' :
                  field === 'bonds' ? 'Bonds' :
                    field === 'cash' ? 'Cash' :
                      'Other'}
            </label>
            <div className='input-container'>
              <input
                className='rounded-corners'
                type='number'
                id={field}
                value={allocation[field] || 0} // Safely access allocation values
                onChange={(e) => handleAllocationChange(field, e.target.value)}
                max={100}
              />
              <span className='percentage-symbol'>%</span>
            </div>
          </div>
        ))}

        <div className={`total-allocation-container ${customizeSettings.percentageState}`}>
          {customizeSettings.percentageState === 'good' && <GoodCheckIcon />}
          {customizeSettings.percentageState === 'warning' && <AlertTriangleIcon className='triangle-icon-warning' />}
          {customizeSettings.percentageState === 'bad' && <AlertTriangleIcon className='triangle-icon-bad' />}
          <span>Total allocation</span>
          <span className='total-allocation-text font-weight-400'>
            <span className={`total-allocation-var ${customizeSettings.percentageState} font-weight-600`}>
              {isNaN(totalAllocation) ? 0 : totalAllocation}%
            </span> <span className={`total-allocation-slash ${customizeSettings.percentageState}`}>/</span> 100%
          </span>
        </div>
      </div>

      {customizeSettings.percentageState !== 'good' &&
      <div className={`allocation-error-message ${customizeSettings.percentageState} font-weight-400`}>
        <p>Total allocation must be 100%</p>
      </div>
      }

      <button 
        className='update-portfolio update-button customization-full-width font-weight-600' 
        onClick={updatePortfolio}
        disabled={isDisabled}
      >Update Portfolio</button>

      <button 
        className='save-custom-portfolio save-button customization-full-width font-weight-600' 
        onClick={saveCustomPortfolio}
        disabled={isDisabled}
      >Save Custom Portfolio</button>
    </div>
  );
}

export default PortfolioTab;
