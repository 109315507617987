import React from 'react';
import '../../css/ChartTools/CustomModebar.css';
import { ReactComponent as PointerIcon } from '../../images/customModebar/EICustomToolPointer.svg';
import { ReactComponent as ZoomInIcon } from '../../images/customModebar/EICustomToolZoomIn.svg';
import { ReactComponent as ZoomOutIcon } from '../../images/customModebar/EICustomToolZoomOut.svg';
import { ReactComponent as PanIcon } from '../../images/customModebar/EICustomToolPan.svg';
import { ReactComponent as ResetAxesIcon } from '../../images/customModebar/EICustomToolResetAxes.svg';
import { ReactComponent as AutoScaleIcon } from '../../images/customModebar/EICustomToolAutoScale.svg';
import { ReactComponent as ZoomSelectionIcon } from '../../images/customModebar/EICustomToolZoomSelection.svg';
import Plotly from 'plotly.js'

const CustomModeBar = ({ selectedTool, setSelectedTool, plotlyGraphId }) => {
	const minLimit = new Date('1925-01-01').getTime();
	const maxLimit = new Date().getTime();

	const pointer = (elementId) => {
		try {
			setSelectedTool(elementId);
			Plotly.relayout(plotlyGraphId, { dragmode: false });
		} catch (error) { }
	}

	const zoomIn = (elementId, incrementFactor = 0.2) => {
		setSelectedTool(elementId);

		const graphDiv = document.getElementById(plotlyGraphId);  // Get the graph element

		// Get the current x-axis range, parse date strings if necessary
		let currentXRange = graphDiv.layout.xaxis.range || [new Date('1925-01-01').getTime(), new Date().getTime()];

		// Convert date strings to timestamps if needed
		if (typeof currentXRange[0] === 'string') {
			currentXRange[0] = new Date(currentXRange[0]).getTime();
		}
		if (typeof currentXRange[1] === 'string') {
			currentXRange[1] = new Date(currentXRange[1]).getTime();
		}

		// Check if the parsed values are valid numbers (not NaN)
		if (isNaN(currentXRange[0]) || isNaN(currentXRange[1])) {
			console.error('Invalid x-axis range: ', currentXRange);
			return; // Exit if there's an issue with the x-axis range
		}

		const xMid = (currentXRange[0] + currentXRange[1]) / 2;

		// Calculate zoomed-in range for x-axis
		const xZoomedInRange = [
			xMid - (xMid - currentXRange[0]) * (1 - incrementFactor),
			xMid + (currentXRange[1] - xMid) * (1 - incrementFactor)
		];

		// Define zoom limits
		const minLimit = new Date('1925-01-01').getTime();
		const maxLimit = new Date().getTime();

		// Clamp the zoom range within the min and max limits
		let currentMin = xZoomedInRange[0] < minLimit ? minLimit : xZoomedInRange[0];
		let currentMax = xZoomedInRange[1] > maxLimit ? maxLimit : xZoomedInRange[1];

		// Check again for NaN before relayout
		if (isNaN(currentMin) || isNaN(currentMax)) {
			console.error('Invalid calculated zoom range:', [currentMin, currentMax]);
			return;
		}

		// Apply the new zoomed-in range
		Plotly.relayout(plotlyGraphId, {
			'xaxis.range': [currentMin, currentMax],
		});
	};

	function zoomOut(elementId, zoomFactor = 0.1) {
		setSelectedTool(elementId);

		const graphDiv = document.getElementById(plotlyGraphId);

		// Get the current x-axis range, or set default values if undefined
		let currentXRange = graphDiv.layout.xaxis.range || [new Date('2020-10-04').getTime(), new Date('2023-12-04').getTime()];

		// Convert date strings to timestamps if needed
		if (typeof currentXRange[0] === 'string') {
			currentXRange[0] = new Date(currentXRange[0]).getTime();
		}
		if (typeof currentXRange[1] === 'string') {
			currentXRange[1] = new Date(currentXRange[1]).getTime();
		}

		// Ensure that currentZoom has valid numeric values (not NaN)
		if (isNaN(currentXRange[0]) || isNaN(currentXRange[1])) {
			console.error('Invalid x-axis range: ', currentXRange);
			return; // Exit if there's an issue with the x-axis range
		}

		// Calculate the new zoom-out range by expanding the current range
		const rangeWidth = currentXRange[1] - currentXRange[0];
		const newRange = [
			currentXRange[0] - rangeWidth * zoomFactor,  // Zoom out on the left
			currentXRange[1] + rangeWidth * zoomFactor   // Zoom out on the right
		];

		// Define zoom limits (1925 - current date)
		const minLimit = new Date('1925-01-01').getTime();
		const maxLimit = new Date().getTime();

		// Clamp the new range within the min and max limits
		let currentMin = newRange[0] < minLimit ? minLimit : newRange[0];
		let currentMax = newRange[1] > maxLimit ? maxLimit : newRange[1];

		// Check again for NaN before applying the relayout
		if (isNaN(currentMin) || isNaN(currentMax)) {
			console.error('Invalid calculated zoom range:', [currentMin, currentMax]);
			return;
		}

		// Apply the new zoomed-out range
		Plotly.relayout(graphDiv, {
			'xaxis.range': [currentMin, currentMax],
		});
	}

	const pan = (elementId) => {
		setSelectedTool(elementId);
		Plotly.relayout(plotlyGraphId, { dragmode: 'pan' });
	}

	const resetAxes = (elementId) => {
		setSelectedTool(elementId);
		Plotly.relayout(plotlyGraphId, {
			'xaxis.range': ['1926-01-01', '2024-06-01'],
		});
	};

	const autoScale = (elementId) => {
		// need to compare this to the autoscale in the official modebar to make sure it does that same thing.
		setSelectedTool(elementId);
		// Ensure the plot is available and fully initialized
		Plotly.relayout(plotlyGraphId, {
			'xaxis.autorange': true,
			'yaxis.autorange': true,
		})
	}

	// This works well from testing so far. Other zoom items error out most of the time.
	const zoomSelection = (elementId) => {
		setSelectedTool(elementId);

		Plotly.relayout(plotlyGraphId, { dragmode: 'zoom' });

		const graphDiv = document.getElementById(plotlyGraphId);
		graphDiv.on('plotly_selected', (eventData) => {
			if (eventData) {
				const xRange = [eventData.range.x[0], eventData.range.x[1]];
				const yRange = [eventData.range.y[0], eventData.range.y[1]];

				Plotly.relayout(plotlyGraphId, {
					'xaxis.range': xRange,
					'yaxis.range': yRange
				});
			}
		});
	};


	const tools = [
		{ id: 'PointerTool', icon: <PointerIcon alt="Pointer Tool" />, onClick: () => pointer('PointerTool') },
		{ id: 'ZoomInTool', icon: <ZoomInIcon alt="Zoom In Tool" />, onClick: () => zoomIn('ZoomInTool') },
		{ id: 'ZoomOutTool', icon: <ZoomOutIcon alt="Zoom Out Tool" />, onClick: () => zoomOut('ZoomOutTool') },
		{ id: 'PanTool', icon: <PanIcon />, onClick: () => pan('PanTool') },
		{ id: 'ResetAxesTool', icon: <ResetAxesIcon />, onClick: () => resetAxes('ResetAxesTool') },
		{ id: 'AutoScaleTool', icon: <AutoScaleIcon />, onClick: () => autoScale('AutoScaleTool') },
		{ id: 'ZoomSelectionTool', icon: <ZoomSelectionIcon />, onClick: () => zoomSelection('ZoomSelectionTool') }
	];

	return (
		<div className={`custom-modebar`}>
			<div className='custom-modebar-section'>
				{tools.map(tool => (
					<div key={tool.id} className='custom-modebar-container'>
						<button
							id={tool.id}
							className={`custom-modebar-item-container icon ${selectedTool === tool.id ? 'selected' : ''}`}
							onClick={tool.onClick}
						>
							{tool.icon}
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomModeBar;
