import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import html2canvas from 'html2canvas';
import './App.css';
import PageContentCard from './components/Cards/PageContentCard';
import MultiLineCSVChart from './components/Charts/InterestRateInflationChart';
import Header from './components/Header/Header';
import ChartTypeButtonsContainer from './components/ChartTypeButtonsContainer/ChartTypeButtonsContainer';
import SideMenu from './components/SideMenus/SideMenu';
import SideMenuSkinny from './components/SideMenus/SideMenuSkinny';
import Footer from './components/Footer/Footer';
import WorldEventsMenu from './components/WorldEventsMenu/WorldEventsMenu';
import RecessionsChart from './components/Charts/RecessionsChart';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './components/Buttons/LoginButton';
import CommoditiesChart from './components/Charts/CommoditiesChart';
import PortfolioChart from './components/Charts/PortfolioChart';
import AssetFlowsChart from './components/Charts/AssetFlowsChart';
import SBBI30YearChart from './components/Charts/SBBI30YearChart';
import EconomicCyclesChart from './components/Charts/EconomicCyclesChart';
import ReturnsDefaultTable from './components/Tables/ReturnsDefaultTable';
import Returns30YearTable from './components/Tables/Returns30YearTable';
import FullAndexChart from './components/Charts/FullAndexChart';
import educatedInvestorLogo from './images/_logo_educated_investor.svg';
import CustomizeButton from './components/Buttons/CustomizeButton'
import CustomizePopup from './components/SideMenus/CustomizeMenu/CustomizePopup';
import { CustomizeContext } from './context/CustomizeContext';
import { debounce } from './helpers';
import ModebarContainer from './components/ChartTools/ModebarContainer';


function App() {
	const [
		selectedButton,
		setSelectedButton
	] = useState('Full Andex');
	const [
		isWorldEventsMenuVisible,
		setIsWorldEventsMenuVisible
	] = useState(false);
	const {
		customizeSettings,
		setIsCustomizeMenuVisible,
		setAmountInvested,
		setActivePortfolioType
	} = useContext(CustomizeContext);
	const [
		isSideMenuOpen,
		setIsSideMenuOpen
	] = useState(true);
	const [
		isPresetationModeOn,
		setIsPresetationModeOn
	] = useState(false);
	const [
		isLoading,
		setIsLoading
	] = useState(false);
	const [
		updateCounter,
		setUpdateCounter
	] = useState(0);
	const [
		selectedSideMenuItem,
		setSelectedSideMenuItem
	] = useState('Full Andex');
	const [dimensions, setDimensions] = React.useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const handleResize = () => {
		setDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}

	React.useEffect(() => {
		window.addEventListener("resize", debounce(handleResize), false);
	}, []);

	const modebarOnForSideMenuItems = ['Full Andex', 'Past 30 years'] // Leave open for admin setting on off later
	const pageContentRef = useRef();

	useEffect(
		() => {
			if (selectedButton === 'Events' && selectedSideMenuItem === 'Full Andex') {
				setIsWorldEventsMenuVisible(true);
			} else {
				setIsWorldEventsMenuVisible(false);
			}
		},
		[
			selectedButton, selectedSideMenuItem
		]
	);

	const exportChart = () => {
		if (pageContentRef.current) {
			setIsLoading(true);
			setTimeout(() => {
				html2canvas(pageContentRef.current)
					.then((canvas) => {
						const link = document.createElement('a');
						link.href = canvas.toDataURL('image/png');
						link.download = 'chart.png';
						link.click();
						setIsLoading(false);
					})
					.catch((error) => {
						console.error('html2canvas error:', error);
						setIsLoading(false);
					});
			}, 0);
		} else {
			console.error('pageContentRef.current is null or undefined');
		}
	};

	const toggleSideMenu = () => {
		if (isPresetationModeOn) {
			setIsPresetationModeOn(false);
		} else {
			setIsSideMenuOpen(!isSideMenuOpen);
		}
	};

	const handleSideMenuItemSelected = (sideMenuItemName) => {
		setSelectedSideMenuItem(sideMenuItemName);
	};

	const togglePresentationMode = () => {
		setIsPresetationModeOn(!isPresetationModeOn);
	};

	const { isAuthenticated } = useAuth0();

	useEffect(
		() => {
			setUpdateCounter((x) => x + 1);
		},
		[
			isSideMenuOpen,
			isPresetationModeOn,
			selectedButton,
			isWorldEventsMenuVisible,
			customizeSettings.isCustomizeMenuVisible,
			dimensions
		]
	);

	useEffect(
		() => {
			if (updateCounter % 2 === 0) {
				setUpdateCounter((x) => x + 1);
			}
		},
		[
			updateCounter
		]
	);

	const renderCharts = () => {
		if (updateCounter % 2 === 0) {
			return <Fragment />;
		}

		if (selectedSideMenuItem === 'Full Andex') {
			switch (selectedButton) {
				case 'Full Andex':
					return (
						<Fragment>
							{/* <div style={{ position: 'relative', width: '100%', height: 700 }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 10,
                                        width: 'calc(100% - 10px)'
                                    }}
                                >
                                    <RecessionsChart className="RecessionsChart" />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 10,
                                        width: 'calc(100% - 10px)'
                                    }}
                                >
                                    <SBBIDefaultChart />
                                </div>
                            </div>
                            <EconomicCyclesChart />
                            <InterestRateInflationChart />
                            <CommoditiesChart />
                            <PortfolioChart assetMix="balanced" /> */}
							<FullAndexChart />
						</Fragment>
					);
				case 'Expansion, contraction & recovery periods':
					return <EconomicCyclesChart />;
				case 'Recessions':
					return <RecessionsChart className="RecessionsChart" showLegend={true} />;
				case 'Commodities':
					return <CommoditiesChart />;
				case 'Prime interest rate vs inflation %':
					return <MultiLineCSVChart />;
				case 'Events':
					return (
						<div style={{ position: 'relative', width: '100%', height: 700 }}>
							<div
								style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
							>
								<RecessionsChart className="RecessionsChart" selectedButton={selectedButton} />
							</div>
						</div>
					);
				default:
					return null;
			}
		} else if (selectedSideMenuItem === 'Past 30 years') {
			return (
				<div style={{ width: '100%', backgroundColor: '#fff' }}>
					<SBBI30YearChart />
					<div style={{ display: 'flex', width: '100%' }}>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%', padding: 20 }}>
							<PortfolioChart assetMix="global" />
						</div>
						<div style={{ flex: 1, padding: 20 }}>
							<AssetFlowsChart />
						</div>
					</div>
					<Returns30YearTable />
				</div>
			);
		} else if (selectedSideMenuItem === 'Percentage returns') {
			return (
				<div
					style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}
				>
					<ReturnsDefaultTable />
				</div>
			);
		}
	};

	const getTitle = () => {
		if (selectedSideMenuItem === 'Full Andex') {
			return selectedButton === 'Full Andex'
				? 'All investment scenarios'
				: selectedButton;
		} else if (selectedSideMenuItem === 'Past 30 years') {
			return 'The Past 30 Years';
		} else if (selectedSideMenuItem === 'Percentage returns') {
			return 'Percentage Returns';
		}
	};

	const getThisMonthsDate = () => {
		const today = new Date();
		const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

		const day = firstOfMonth.getDate().toString().padStart(2, '0');
		const month = firstOfMonth.toLocaleString('default', { month: 'long' });
		const year = firstOfMonth.getFullYear();

		return `${day} ${month} ${year}`;
	};

	if (isAuthenticated) {
		return (
			<div className="App">
				<div className="layout-container">
					<div className="sidemenu-wrapper">
						{!isPresetationModeOn && (
							<div className="sidemenu-container">
								{isSideMenuOpen ? (
									<SideMenu
										selectedSideMenuItem={selectedSideMenuItem}
										onSideMenuItemSelected={handleSideMenuItemSelected}
									/>
								) : (
									<SideMenuSkinny
										selectedSideMenuItem={selectedSideMenuItem}
										onSideMenuItemSelected={handleSideMenuItemSelected}
									/>
								)}
							</div>
						)}
					</div>
					<div className="pagecontent-container">
						{!isPresetationModeOn && (
							<Header
								toggleSideMenu={toggleSideMenu}
								isSideMenuOpen={isSideMenuOpen}
							/>
						)}
						{selectedSideMenuItem === 'Full Andex' && (
							<div className={`buttons-container ${selectedButton === 'Full Andex' ? 'chart-type-customize-button-container' : ''}`}>
								<div className='chart-types-buttons-container'>
									<ChartTypeButtonsContainer
										selectedButton={selectedButton}
										setSelectedButton={setSelectedButton}
									/>
								</div>
								{selectedButton === 'Full Andex' && (
									<div className='customize-button-container'>
										<CustomizeButton
											customizeSettings={customizeSettings}
											setIsCustomizeMenuVisible={() => setIsCustomizeMenuVisible(!customizeSettings.isCustomizeMenuVisible)}
										/>
									</div>
								)}
							</div>
						)}
						<div className="main-content expanded">
							{isLoading && (
								<div className="loading-indicator">Processing...</div>
							)}
							{modebarOnForSideMenuItems.includes(selectedSideMenuItem) && (
								<div className="">
									<ModebarContainer />
								</div>
							)}
							<PageContentCard
								ref={pageContentRef}
								title={getTitle()}
								selectedSideMenuItem={selectedSideMenuItem}
								date={getThisMonthsDate()}
								togglePresentationMode={togglePresentationMode}
								isPresetationModeOn={isPresetationModeOn}
								exportChart={exportChart}
							>
								{renderCharts()}
							</PageContentCard>
							{isWorldEventsMenuVisible && (
								<WorldEventsMenu
									onClose={() => setIsWorldEventsMenuVisible(false)}
								/>
							)}
							{customizeSettings.isCustomizeMenuVisible && (
								<CustomizePopup />
							)}
						</div>
						{!isPresetationModeOn && <Footer />}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh'
				}}
			>
				<div className="logo-container">
					<img
						src={educatedInvestorLogo}
						alt="Educated Investor Logo"
						className="main-logo"
					/>
				</div>
				<div>
					<LoginButton />
				</div>
			</div>
		);
	}
}

export default App;
