import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import {useContext} from 'react';
import {ReactComponent as PlusIcon} from '../../../images/plus-icon.svg';
import {ReactComponent as MinusIcon} from '../../../images/minus-icon.svg';
import { CustomizeContext } from '../../../context/CustomizeContext';

const SettingsTab = () => {
    const { 
            customizeSettings,
            setAmountInvested
        } = useContext(CustomizeContext);

    const applySettings = () => {
        console.log('Definitely applied settings for realsies.');
    }

    const incrementInvestment = () => {
        setAmountInvested(customizeSettings.amountInvested + 1);
    }

    const decremenetInvestment = () => {
        if(customizeSettings.amountInvested > 1){
        setAmountInvested(customizeSettings.amountInvested - 1);
        }
    }

    const clearSettings = () => {
        setAmountInvested(1);
    }

    const handleInputChange = (e) => {
        const value = e.target.value.replace(/[^\d]/g, '');
        const numberValue = Number(value);
        if (numberValue >= 1) {
            setAmountInvested(numberValue);
        } else {
            setAmountInvested(1);
        }
    }

    return (
        <div className="tab-content settings-tab-content font-weight-400">
          <label htmlFor='settingsAmountInvestedInput'>Amount invested</label>
          <div className="number-input-div">
                <button className='decrement' onClick={decremenetInvestment}><MinusIcon /></button>
                <input type='text' id='settingsAmountInvestedInput' value={`$${customizeSettings.amountInvested}`} name='amountInvested' onChange={handleInputChange} />
                <button className='increment' onClick={incrementInvestment}><PlusIcon /></button>
        </div>
          <button className="apply-button customization-full-width font-weight-600" onClick={applySettings}>Apply Settings</button>
          <div className='button-container'>
            <button className="clear-button customization-half-width font-weight-600" onClick={clearSettings}>Clear Settings</button>
          </div>
        </div>
      );
}
export default SettingsTab