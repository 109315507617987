import React, { useState } from 'react';
import '../../css/SideMenus/SideMenu.css';

import educatedInvestorLogoCollapsed from '../../images/sideMenuSkinny/_logo_educated_investor_collapsed.svg';
import andexChartIcon from '../../images/sideMenu/andex-chart-icon.svg';
import andexChartIconBlack from '../../images/sideMenu/andex-chart-icon-black.svg';
import chartCandleIcon from '../../images/sideMenu/tabler_chart-candle.svg';
import chartPercentageIcon from '../../images/sideMenu/tabler_percentage.svg';
import chartCalendarIcon from '../../images/sideMenu/tabler_calendar-repeat.svg';
import chartArrowIcon from '../../images/sideMenu/arrow_drop_down.svg';

const SideMenuSkinny = ({ selectedSideMenuItem, onSideMenuItemSelected }) => {
	const [
		submenuVisible,
		setSubmenuVisible
	] = useState(true);

	const toggleSubmenu = () => {
		setSubmenuVisible(!submenuVisible);
	};

	const handleItemClick = (itemName) => {
		onSideMenuItemSelected(itemName);
	};

	return (
		<div className="sidebar">
			<div className="sidebar-section">
				<div className="logo-container">
					<img
						src={educatedInvestorLogoCollapsed}
						alt="Educated Investor Logo"
						className="main-logo"
					/>
				</div>
				<div className="menu-title-container-skinny" onClick={toggleSubmenu}>
					<img src={andexChartIcon} alt="Rolling Periods" />
				</div>
				<div
					className={`submenu-container ${submenuVisible ? 'expanded' : ''}`}
				>
					<div
						className={`menu-item-container-skinny ${selectedSideMenuItem ===
						'Full Andex'
							? 'selected'
							: ''}`}
						onClick={() => handleItemClick('Full Andex')}
					>
						<img src={andexChartIconBlack} alt="Full Andex" />
					</div>
					<div
						className={`menu-item-container-skinny ${selectedSideMenuItem ===
						'Percentage returns'
							? 'selected'
							: ''}`}
						onClick={() => handleItemClick('Percentage returns')}
					>
						<img src={chartPercentageIcon} alt="Percentage returns" />
					</div>
					<div
						className={`menu-item-container-skinny ${selectedSideMenuItem ===
						'Past 30 years'
							? 'selected'
							: ''}`}
						onClick={() => handleItemClick('Past 30 years')}
					>
						<img src={chartCalendarIcon} alt="Past 30 years" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideMenuSkinny;
