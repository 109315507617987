import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ChartOverlayWrapper from './ChartOverlayWrapper';

function InterestRateInflationChart() {
	const { getAccessTokenSilently } = useAuth0();
	const [
		data,
		setData
	] = useState([]);

	useEffect(
		() => {
			(async () => {
				try {
					const token = await getAccessTokenSilently();
					const response = await fetch(
						`${process.env.REACT_APP_API_BASE_URL}/prime-interest-vs-inflation`,
						{
							headers: {
								Authorization: `Bearer ${token}`
							}
						}
					);
					setData(await response.json());
				} catch (e) {
					console.error(e);
				}
			})();
		},
		[
			getAccessTokenSilently
		]
	);

	if (!data || data.length === 0) {
		return <div>Loading Interest/Inflation chart...</div>;
	}

	const tracePrimeInterest = {
		name: 'Prime Interest',
		x: data.map((e) => e.Date),
		y: data.map((e) => e.prime_interest),
		hoverinfo: 'text',
		hovertext: data.map((e) => {
			return `<b>Prime Interest Rate</b><br />${new Date(e.Date)
				.toISOString()
				.split('T')[0]}<br />${e.prime_interest}`;
		}),
		type: 'scatter',
		mode: 'lines',
		fill: 'tozeroy', // Fill area below the line
		line: {
			color: '#F3C317'
		},
		fillcolor: 'rgba(243, 195, 23, 1)', // Solid color for fill area
		yaxis: 'y2',
	};

	const traceInflation = {
		name: 'Inflation',
		x: data.map((e) => e.Date),
		y: data.map((e) => e.rolling_inflation),
		hoverinfo: 'text',
		hovertext: data.map((e) => {
			return `<b>Inflation Rate</b><br />${new Date(e.Date)
				.toISOString()
				.split('T')[0]}<br />${e.rolling_inflation}`;
		}),
		type: 'scatter',
		mode: 'lines',
		fill: 'tozeroy', // Fill area below the line
		line: {
			color: '#868686'
		},
		fillcolor: 'rgba(134, 134, 134, 1)', // Solid color for fill area
		yaxis: 'y3',
	};

	const trace30YearGovernmentBond = {
		name: "30Y Gov't Bond",
		x: data.map((e) => e.Date),
		y: data.map((e) => e.thirty_yr_gov_bond),
		type: 'scatter',
		marker: {
			color: '#EE7623'
		},
		line: {
			color: '#EE7623',
			width: 2 // Adjust line width as needed
		},
		yaxis: 'y3',
	};

	const chartData = [
		tracePrimeInterest,
		traceInflation,
		trace30YearGovernmentBond,
	];

	const yAxisTicks = {
		tickValues: [
			-10,
			-5,
			0,
			5,
			10, 
			15, 
			20
		],
		tickText: [
		  '-10',
		  '-5',
		  '0',
		  '5',
		  '10',
		  '15',
		  '20'
		]
	  };

	const yAxisSettings =
	{
		title: 'Prime Interest',
		domain: [
			0,
			1
		], // Ensure the entire range is visible
		range: [
			-15,
			26
		],
		tickvals: yAxisTicks.tickValues,
        ticktext: yAxisTicks.tickText, // This overlays them nicely. TODO: params or declarative
	};

	const yAxis2Settings = 
	{
		title: 'Inflation',
		position: 1, // Anchor y-axis to the right side
		overlaying: 'y2',
		side: 'right',
	};

	return (
		<div style={{ width: '100%', minWidth: '300px' }}>
			<ChartOverlayWrapper 
				singleChartData={chartData}
				singleChartYAxisSettings={yAxisSettings}
				singleChartYAxis2Settings={yAxis2Settings}
				singleChartTitle={'Prime Interest Rate vs. Inflation Rate'}
			/>
		</div>
	);
}

export default React.memo(InterestRateInflationChart);
