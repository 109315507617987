import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import { EventContext } from '../../context/EventContext';
import { CustomizeContext } from '../../context/CustomizeContext';
import {
    dollarsWithCommas,
    getSbbiTraceColor,
    getSbbiTraceCommonName,
    calculateContinuousCompoundingAPR,
 } from '../../helpers';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function FullAndexChart() {
    const { getAccessTokenSilently } = useAuth0();
    const { checkedItems } = useContext(EventContext);

    // Toggle on off charts (hide the data if customize option is off)
    const {
        customizeSettings,
    } = useContext(CustomizeContext);

    const allDisabled =
        !customizeSettings.chartTypes.All &&
        !customizeSettings.chartTypes.SBBI &&
        !customizeSettings.chartTypes.Expansions &&
        !customizeSettings.chartTypes.Recessions &&
        !customizeSettings.chartTypes.Commodities &&
        !customizeSettings.chartTypes.InterestVsInflation
    // && !customizeSettings.chartTypes.TaxRate &&
    //!customizeSettings.chartTypes.Government &&
    //!customizeSettings.chartTypes.SP500

    const recessionEnabled =
        !customizeSettings.chartTypes.All &&
        !customizeSettings.chartTypes.SBBI &&
        !customizeSettings.chartTypes.Expansions &&
        customizeSettings.chartTypes.Recessions &&
        !customizeSettings.chartTypes.Commodities &&
        !customizeSettings.chartTypes.InterestVsInflation
    // && !customizeSettings.chartTypes.TaxRate &&
    //!customizeSettings.chartTypes.Government &&
    //!customizeSettings.chartTypes.SP500

    const countNonSBBIChartsOn = Object.entries(customizeSettings.chartTypes)
        .filter(([key, value]) => (key === 'Expansions' || key === 'Commodities' || key === 'InterestVsInflation') && value === true)
        .length;

    // Not implemented. Leaving these to keep track of what we need to add.
    const [showTaxRate, setShowTaxRate] = useState(true);
    const [showGovernment, setShowGovernment] = useState(true);
    const [showSP500, setShowSP500] = useState(true);
    // End toggle on off

    const [
        sbbi,
        setSbbi
    ] = useState([]);
    const [
        loadingSbbi,
        setLoadingSbbi
    ] = useState(true);

    const [
        historicalEvents,
        setHistoricalEvents
    ] = useState([]);
    const [
        loadingEvents,
        setLoadingEvents
    ] = useState(true);

    const [
        recessions,
        setRecessions
    ] = useState([]);
    const [
        loadingRecessions,
        setLoadingRecessions
    ] = useState(true);

    const [
        economicCycles,
        setEconomicCycles
    ] = useState([]);
    const [
        loadingEconomicCycles,
        setLoadingEconomicCycles
    ] = useState(true);

    const [
        commodities,
        setCommodities
    ] = useState([]);
    const [
        loadingCommodities,
        setLoadingCommodities
    ] = useState(true);

    const [
        interestRateInflationData,
        setInterestRateInflationData
    ] = useState([]);
    const [
        loadingInterestRateInflationData,
        setLoadingInterestRateInflationData
    ] = useState(true);

    const [
        portfoliosData,
        setPortfoliosData
    ] = useState([]);
    const [
        loadingPortfoliosData,
        setLoadingPortfoliosData
    ] = useState(true);

    useEffect(
        () => {
            const fetchData = async () => {
                try {
                    const token = await getAccessTokenSilently();
                    const sbbiResponse = await fetch(`${API_BASE_URL}/sbbi-default`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setSbbi(await sbbiResponse.json());
                    setLoadingSbbi(false);

                    const eventsResponse = await fetch(
                        `${API_BASE_URL}/historical-events`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                    setHistoricalEvents(await eventsResponse.json());
                    setLoadingEvents(false);

                    const recessionsResponse = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/recessions`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                    setRecessions(await recessionsResponse.json());
                    setLoadingRecessions(false);

                    const economicCyclesResponse = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/expansions-contractions`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                    setEconomicCycles(await economicCyclesResponse.json());
                    setLoadingEconomicCycles(false);

                    const commoditiesResponse = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/commodities`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                    setCommodities(await commoditiesResponse.json());
                    setLoadingCommodities(false);

                    const interestRateInflationResponse = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/prime-interest-vs-inflation`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                    setInterestRateInflationData(
                        await interestRateInflationResponse.json()
                    );
                    setLoadingInterestRateInflationData(false);

                    //const portfoliosResponse = customizeSettings.portfolioData;
                    //setPortfoliosData(await portfoliosResponse.json());
                    setLoadingPortfoliosData(false);
                } catch (e) {
                    console.error(e);
                    setLoadingSbbi(false);
                    setLoadingEvents(false);
                    setLoadingRecessions(false);
                    setLoadingEconomicCycles(false);
                    setLoadingCommodities(false);
                    setLoadingInterestRateInflationData(false);
                    setLoadingPortfoliosData(false);
                }
            };
            fetchData();
        },
        [
            getAccessTokenSilently
        ]
    );

    const filteredEvents = useMemo(
        () => {
            if (!historicalEvents || historicalEvents.length === 0) return [];

            // Filter events based on checkedItems
            return historicalEvents.filter((event) => {
                if (checkedItems.allEvents) {
                    return true; // Show all events if 'All Events' is checked
                } else {
                    // Filter by specific types if checkboxes are checked
                    return (
                        (checkedItems.warConflict && event.Type === 'Wars/Conflicts') ||
                        (checkedItems.fiscalEconomic && event.Type === 'Fiscal/Economic') ||
                        (checkedItems.worldGeopolitical &&
                            event.Type === 'World/Geopolitical') ||
                        (checkedItems.congressionalGovernment &&
                            event.Type === 'Congressional/Government') ||
                        (checkedItems.scienceTechnology &&
                            event.Type === 'Science/Technology') ||
                        (checkedItems.naturalGeological &&
                            event.Type === 'Natural/Geological')
                    );
                }
            });
        },
        [
            historicalEvents,
            checkedItems
        ]
    );

    const annotations = useMemo(
        () => {
            const BAND_RANGE = [
                0,
                4.8
            ];
            const BAND_INCREMENT = 0.2;
            let yBand = 0;
            return _(filteredEvents)
                .orderBy('Date')
                .map((e) => {
                    yBand += BAND_INCREMENT;
                    yBand = yBand > BAND_RANGE[1] ? BAND_RANGE[0] : yBand;
                    let dynamicXAnchor = 'left';
                    let dynamicAX = 4;
                    if (e.Date > '2014-01-01') {
                        dynamicXAnchor = 'right'; // Adjust as needed
                        dynamicAX = -4;
                    }
                    return {
                        x: e.Date,
                        y: yBand,
                        text: e.Event,
                        showarrow: true,
                        arrowhead: 6,
                        ax: dynamicAX,
                        ay: 0,
                        xanchor: dynamicXAnchor,
                        font: { color: 'rgba(0, 0, 0, 0.6)', size: 10 },
                        arrowcolor: 'rgba(0, 0, 0, 0.6)'
                    };
                })
                .value();
        },
        [
            filteredEvents
        ]
    );

    if (
        loadingSbbi ||
        loadingEvents ||
        loadingRecessions ||
        loadingEconomicCycles ||
        loadingCommodities ||
        loadingInterestRateInflationData ||
        loadingPortfoliosData
    ) {
        return <div>Loading Full Andex view...</div>;
    }

    if (!sbbi || sbbi.length === 0) {
        return <div>No data available for the SBBI (all-time) chart.</div>;
    }

    function generateCustomLegend(metric, sbbi) {
        const firstValue = sbbi[0][metric];
        const lastValue = sbbi.slice(-1)[0][metric];
        const color = getSbbiTraceColor(metric); // Assuming getSbbiTraceColor(metric) is defined elsewhere
        const name = getSbbiTraceCommonName(metric); // Assuming getSbbiTraceCommonName(metric) is defined elsewhere

        const apr = calculateContinuousCompoundingAPR(firstValue, lastValue, 98);

        const legendHTML = `<span style="color:${color}"><br />${name}<br />${dollarsWithCommas(
            lastValue
        )} (${apr}%)</span>`;

        return legendHTML;
    }

    // Get list of metrics (excluding 'Date')
    const sbbiMetrics = (() => {
        switch (customizeSettings.activePortfolioType) {
            case 'Balanced':
                return Object.keys(sbbi[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Growth_Portfolio' && key !== 'Income_Portfolio' && key !== 'Global_Portfolio'
                );
            case 'Income':
                return Object.keys(sbbi[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Growth_Portfolio' && key !== 'Balanced_Portfolio' && key !== 'Global_Portfolio'
                );
            case 'Growth':
                return Object.keys(sbbi[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Income_Portfolio' && key !== 'Balanced_Portfolio' && key !== 'Global_Portfolio'
                );
            default:
                return Object.keys(sbbi[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Income_Portfolio' && key !== 'Balanced_Portfolio' && key !== 'Growth_Portfolio'
                );
        }
    })();
    

    const sbbiTraces = sbbiMetrics.flatMap((metric) => {
        if (metric === 'CPIAUCNS') {
            // Separate data into above and below 1
            const aboveOne = sbbi
                .map((entry) => entry[metric])
                .map((val) => (val >= 1 ? val : null)); // Null values for below threshold
            const belowOne = sbbi
                .map((entry) => entry[metric])
                .map((val) => (val < 1 ? val : null)); // Null values for above threshold

            const hoverText = sbbi.map(
                (entry) =>
                    getSbbiTraceCommonName(metric) +
                    '<br />' +
                    entry.Date.toString() +
                    'x' +
                    '<br />' +
                    '$' +
                    Math.floor(entry[metric])
            );

            const fullTrace = {
                name: generateCustomLegend(metric, sbbi),
                x: sbbi.map((entry) => entry.Date),
                y: sbbi.map((entry) => entry[metric]),
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                showlegend: true,
                legendgroup: metric,
                line: { color: getSbbiTraceColor(metric) },
                hoverinfo: 'text',
                hovertext: hoverText
            };

            const oneTrace = {
                name: 'SBBI One',
                x: sbbi.map((entry) => entry.Date),
                y: sbbi.map((entry) => 1),
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                showlegend: false,
                legendgroup: metric,
                line: { color: 'rgba(0, 0, 0, 0)' }
            };

            // Create traces for above 1, below 1, and fill up to 1
            const positiveTrace = {
                name: `${getSbbiTraceCommonName(metric)} (Positive)`,
                x: sbbi.map((entry) => entry.Date),
                y: aboveOne,
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                line: { color: 'rgba(0, 0, 0, 0)' },
                fill: 'tonexty',
                fillcolor: 'rgba(150, 150, 150, 0.45)',
                showlegend: false,
                legendgroup: metric,
            };

            const negativeTrace = {
                name: `${getSbbiTraceCommonName(metric)} (Negative)`,
                x: sbbi.map((entry) => entry.Date),
                y: belowOne,
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                line: { color: 'rgba(0, 0, 0, 0)' },
                fill: 'tonexty',
                fillcolor: 'rgba(150, 150, 150, 0.45)',
                showlegend: false,
                legendgroup: metric,
            };

            //Add oneTrace before each of the others, so "fill to next y" fills to one
            return [
                fullTrace,
                oneTrace,
                positiveTrace,
                oneTrace,
                negativeTrace
            ];
        } else {
            // For other metrics, create a single trace
            const hoverText = sbbi.map(
                (entry) =>
                    getSbbiTraceCommonName(metric) +
                    '<br />' +
                    entry.Date +
                    '<br />' +
                    '$' +
                    Math.floor(entry[metric])
            );

            return {
                name: generateCustomLegend(metric, sbbi),
                x: sbbi.map((entry) => entry.Date),
                y: sbbi.map((entry) => entry[metric]),
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                line: { color: getSbbiTraceColor(metric) },
                fill: 'none',
                showlegend: true,
                hoverinfo: 'text',
                hovertext: hoverText
            };
        }
    });

    // Calculate final y-values and sort traces by highest final y-value to lowest
    // This assists the legend, that will spit out the traces in the order added to
    // the chart data. We want them ordered so they sort of line up with the ending
    // points of the traces.
    const sortedSbbiTraces = sbbiTraces.slice().sort((traceA, traceB) => {
        const showLegendA = traceA.showlegend === true;
        const showLegendB = traceB.showlegend === true;

        if (showLegendA && showLegendB) {
            // Sort legends with showlegend === true by final Y value
            const finalYValueA = findFinalYValue(traceA);
            const finalYValueB = findFinalYValue(traceB);
            return finalYValueB - finalYValueA; // Sort in ascending order
        } else if (showLegendA && !showLegendB) {
            // traceA should come before traceB
            return -1;
        } else if (!showLegendA && showLegendB) {
            // traceB should come before traceA
            return 1;
        } else {
            // Neither has showlegend === true, maintain original order
            return 0;
        }
    });

    function findFinalYValue(trace) {
        if (Array.isArray(trace.y)) {
            for (let i = trace.y.length - 1; i >= 0; i--) {
                if (trace.y[i] !== null && !isNaN(trace.y[i])) {
                    return trace.y[i];
                }
            }
        }
        return -Infinity; // Return a very low value if no valid y-value found
    }

    const sbbiYAxisTicks = {
        tickValues: [
            1,
            10,
            100,
            1000,
            10000,
            100000,
            1000000,
            10000000
        ],
        tickText: [
            '1',
            '10',
            '100',
            '1K',
            '10K',
            '100K',
            '1M',
            '10M'
        ]
    };

    // Get current year
    const currentYear = new Date().getFullYear();

    // Generate years array from 1926 to current year
    const startYear = 1926;
    const years = Array.from(
        { length: currentYear - startYear + 1 },
        (_, index) => {
            const year = startYear + index;
            return year.toString().slice(-2); // Convert year to 2-digit format
        }
    );


    /* Position changing graphs */
    const positionDoughnutGraphVertically = () => {
        let position = 425;

        if (customizeSettings.chartTypes.SBBI) {
            position = customizeSettings.chartTypes.Recessions ? 500 : 425
        } else {
            position = customizeSettings.chartTypes.Recessions ? 90 : 0
        }

        return position;
    }

    const positionRecession = () => {
        let y0 = 0;
        if (recessionEnabled) return 0.5;
        if (customizeSettings.chartTypes.SBBI) {
            switch (countNonSBBIChartsOn) {
                case 3:
                    y0 = 0;
                    break;
                case 2:
                    y0 = 0.15;
                    break;
                case 1:
                    y0 = 0.35;
                    break;
                case 0:
                    y0 = 0.5;
                    break;
                default:
                    break;
            }
        } else if (!customizeSettings.chartTypes.SBBI) {
            switch (countNonSBBIChartsOn) {
                case 3:
                    y0 = 0;
                    break;
                case 2:
                    y0 = 0.15;
                    break;
                case 1:
                    y0 = 0.35;
                    break;
                case 0:
                    y0 = 0.5;
                    break;
                default:
                    break;
            }
        }

        return y0;
    }

    const positionCommodities = () => {
        let yaxis34 = [0.2, 0.3];

        if (customizeSettings.chartTypes.SBBI) {
            yaxis34 = customizeSettings.chartTypes.Expansions ? [0.2, 0.3] : [0.4, 0.5]
        } else if (!customizeSettings.chartTypes.SBBI) {
            yaxis34 = customizeSettings.chartTypes.Expansions ? [0.75, 0.85] : [0.88, 0.98]
        }

        return yaxis34;
    }

    const positionInterestVsInflation = () => {
        let yaxis34 = [0, 0.15];

        if (customizeSettings.chartTypes.SBBI) {
            switch (countNonSBBIChartsOn) {
                case 3:
                    break;
                case 2:
                    //yaxis34 = [0.35, 0.45];
                    yaxis34 = [0.17, 0.32];
                    break;
                case 1:
                    yaxis34 = [0.37, 0.52];
                    break;
                default:
                    break;
            }
        } else if (!customizeSettings.chartTypes.SBBI) {
            switch (countNonSBBIChartsOn) {
                case 3:
                    yaxis34 = [0.57, 0.72];
                    break;
                case 2:
                    yaxis34 = [0.72, 0.87];
                    break;
                case 1:
                    yaxis34 = [0.85, 1];
                    break;
                default:
                    break;
            }
        }

        return yaxis34;
    }


    const recessionShapes = recessions.map((recession) => ({
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: recession.start_date,
        x1: recession.end_date,
        y0: positionRecession(),
        y1: 1,
        fillcolor: '#e0e0e0',
        opacity: 0.5,
        line: {
            width: 0
        },
        layer: 'below',
    }));

    const chartData = [];

    // Handles the plot in two cases where no data is present. (all charts off, or just recessions drawn on)
    if (recessionEnabled) {
        let sampleTrace = sbbiTraces[0];
        sampleTrace = { ...sampleTrace, line: { color: 'rgba(255, 0, 0, 0)' }, showlegend: false }; // Hidden trace to keep graph looking right when SBBI off
        chartData.push(sampleTrace);
    }

    (customizeSettings.chartTypes.SBBI && chartData.push(...sortedSbbiTraces));

    //REGION: Economic Cylces
    const globalStartDate = economicCycles.reduce((min, current) => {
        const currentDate = new Date(current.StartDate);
        return currentDate < min ? currentDate : min;
    }, new Date('9999-12-31'));

    const minYear = globalStartDate.getFullYear();

    // Since return of a recovery is suspect, pin to the max
    // of non-recovery items.
    const maxReturn = Math.max(
        ...economicCycles
            .filter((item) => item.Phase !== 'Recovery')
            .map((item) => Math.abs(item.Return))
    );

    const expansions = economicCycles.filter(
        (item) => item.Phase === 'Expansion'
    );
    const downturns = economicCycles.filter((item) => item.Phase === 'Downturn');
    const recoveries = economicCycles.filter((item) => item.Phase === 'Recovery');

    const calculateXPositionInYears = (startDate, width) => {
        const localStartDate = new Date(startDate);
        const yearsFromGlobalStartDate =
            (localStartDate - globalStartDate) / (1000 * 60 * 60 * 24 * 365);
        const xPositionInYears = yearsFromGlobalStartDate + 0.5 * width;
        return xPositionInYears + minYear;
    };

    const calculateWidth = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const widthInYears = (end - start) / (1000 * 60 * 60 * 24 * 365);
        return widthInYears;
    };

    const calculateDurationInMonths = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diffInMonths =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());

        return diffInMonths;
    };

    function floatToDate(floatNumber) {
        // Extract the year (integer part) and days fraction (decimal part)
        let year = Math.floor(floatNumber);
        let daysFraction = floatNumber - year;

        // Determine if it's a leap year
        let isLeapYear = new Date(year, 1, 29).getMonth() === 1;

        // Calculate total days in the year
        let totalDaysInYear = isLeapYear ? 366 : 365;

        // Calculate the day of the year
        let dayOfYear = Math.floor(daysFraction * totalDaysInYear) + 1; // +1 because dayOfYear is 1-based

        // Convert day of year to date format (day, month, year)
        let date = dayOfYearToDate(dayOfYear, year);

        return date;
    }

    function dayOfYearToDate(dayOfYear, year) {
        // Convert day of year to date format (day, month, year)
        let date = new Date(year, 0); // January 0th is actually December 31st of the previous year
        date.setDate(dayOfYear);
        return date;
    }

    const generateTraces = (phaseData, negative, color, legendStatus) => {
        const filteredPhaseData = phaseData;

        return filteredPhaseData.map((item) => {
            const phase = item.Phase;
            const showLegend = !legendStatus[phase];  // Show legend only once per phase
            legendStatus[phase] = true;

            const itemYValue = phase === 'Recovery'
                ? [negative === true ? -100 : 100]  // Set y to -100 or 100 based on negative
                : [
                    negative === true
                        ? Math.max(-100, Math.min(100, (-1 * 100 * item.Return) / maxReturn))
                        : Math.max(-100, Math.min(100, (100 * item.Return) / maxReturn))
                ];

            const itemYPercentage = parseFloat(itemYValue[0].toFixed(2));

            return {
                x: [
                    floatToDate(
                        calculateXPositionInYears(
                            item.StartDate,
                            calculateWidth(item.StartDate, item.EndDate)
                        )
                    )
                ],
                y: itemYValue,
                width: [
                    calculateWidth(item.StartDate, item.EndDate) * 365 * 24 * 60 * 60 * 1000
                ],
                marker: { color: color },
                name: phase,
                hoverinfo: 'text',
                showlegend: showLegend,
                hovertext: `<b>${phase}</b></br>Start Date: ${new Date(item.StartDate)
                    .toISOString()
                    .split('T')[0]}<br />End Date: ${new Date(item.EndDate)
                        .toISOString()
                        .split('T')[0]}<br />Duration (Months): ${calculateDurationInMonths(
                            item.StartDate,
                            item.EndDate
                        )}</br>Percentage: ${itemYPercentage}%`,
                type: 'bar',
                yaxis: 'y2',
                orientation: 'v',
                legend: 'legend2',
                legendgroup: phase, // Group all traces of the same phase so they all toggle from legend at once.
            };
        });
    };

    // Legend status to control showing legend once per phase
    const legendStatus = {};
    const economicCycleTraces = [
        ...generateTraces(expansions, false, 'rgba(123, 207, 235, 0.7)', legendStatus),
        ...generateTraces(downturns, false, 'rgba(0, 168, 225, 0.7)', legendStatus),
        ...generateTraces(recoveries, false, 'rgba(204, 238, 249, 0.7)', legendStatus),
        ...generateTraces(recoveries, true, 'rgba(204, 238, 249, 0.7)', legendStatus)
    ];

    (customizeSettings.chartTypes.Expansions && chartData.push(...economicCycleTraces));
    //END REGION: Economic Cylces

    //REGION: Commodities
    //const filteredCommoditiesData = commodities.filter(item => new Date(item.Date) >= thirtyYearsAgo);
    const filteredCommoditiesData = commodities;

    const traceOil = {
        name: 'Oil',
        x: filteredCommoditiesData.map((e) => e.Date),
        y: filteredCommoditiesData.map((e) => e.Oil),
        type: 'scatter',
        marker: {
            color: 'background: #414042'
        },
        yaxis: 'y3',
        showlegend: false
    };
    (customizeSettings.chartTypes.Commodities && chartData.push(traceOil));

    const traceGold = {
        name: 'Gold',
        x: filteredCommoditiesData.map((e) => e.Date),
        y: filteredCommoditiesData.map((e) => e.Gold),
        type: 'scatter',
        marker: {
            color: '#F3C317'
        },
        yaxis: 'y4',
        showlegend: false
    };
    (customizeSettings.chartTypes.Commodities && chartData.push(traceGold));
    //END REGION: Commodities

    // REGION: Interest Rate vs. Inflation
    const tracePrimeInterest = {
        name: 'Prime Interest',
        x: interestRateInflationData.map((e) => e.Date),
        y: interestRateInflationData.map((e) => e.prime_interest),
        hoverinfo: 'text',
        hovertext: interestRateInflationData.map((e) => {
            return `<b>Prime Interest Rate</b><br />${new Date(e.Date)
                .toISOString()
                .split('T')[0]}<br />${e.prime_interest}`;
        }),
        type: 'scatter',
        mode: 'lines',
        fill: 'tozeroy', // Fill area below the line
        line: {
            color: '#F3C317'
        },
        fillcolor: 'rgba(243, 195, 23, 1)', // Solid color for fill area
        yaxis: 'y5',
        showlegend: false
    };
    (customizeSettings.chartTypes.InterestVsInflation && chartData.push(tracePrimeInterest));

    const traceInflation = {
        name: 'Inflation',
        x: interestRateInflationData.map((e) => e.Date),
        y: interestRateInflationData.map((e) => e.rolling_inflation),
        hoverinfo: 'text',
        hovertext: interestRateInflationData.map((e) => {
            return `<b>Inflation Rate</b><br />${new Date(e.Date)
                .toISOString()
                .split('T')[0]}<br />${e.rolling_inflation}`;
        }),
        type: 'scatter',
        mode: 'lines',
        fill: 'tozeroy', // Fill area below the line
        line: {
            color: '#868686'
        },
        fillcolor: 'rgba(134, 134, 134, 1)', // Solid color for fill area
        yaxis: 'y6',
        showlegend: false
    };
    (customizeSettings.chartTypes.InterestVsInflation && chartData.push(traceInflation));

    const trace30YearGovernmentBond = {
        name: "30Y Gov't Bond",
        x: interestRateInflationData.map((e) => e.Date),
        y: interestRateInflationData.map((e) => e.thirty_yr_gov_bond),
        type: 'scatter',
        marker: {
            color: '#EE7623'
        },
        line: {
            color: '#EE7623',
            width: 2 // Adjust line width as needed
        },
        yaxis: 'y6',
        xaxis: 'x2', // Bottom-most chart bound to x-axis #2
        showlegend: false
    };
    (customizeSettings.chartTypes.InterestVsInflation && chartData.push(trace30YearGovernmentBond));
    // END REGION: Interest Rate vs. Inflation

    /// REGION: Portfolio (balanced)
    const balancedPortfolioData = customizeSettings.portfolioData && customizeSettings.portfolioData[customizeSettings.activePortfolioType.toLowerCase()];

    const formatLabel = (name, percentage) => {
        const maxLength = 20; // Define a max length for the name for padding
        const namePadded = name.padEnd(maxLength, ' '); // Pad the name with spaces to the right
        return `${namePadded}${percentage}`; // Combine the padded name and percentage
    };

    // Define new colors for the pie chart
    const pieChartColors = [
        '#1268B3',
        '#8CA2D3',
        '#EE7623',
        '#658D3C'
    ];

    // Determine the legend label for LT Government Bonds
    const determineLegendLabel = (commonName) => {
        if (commonName === 'smallStocks') {
            return 'Small Stocks';
        } if (commonName === 'largeStocks') {
            return 'Large Stocks';
        }
        if (commonName === 'bonds') {
            return 'Bonds';
        }
        if (commonName === 'other') {
            return 'Other';
        }
        if (commonName === 'cash') {
            return 'Cash';
        }
        return commonName;
    };

    const balancedPortfolioChartData = [
        {
            values: Object.values(balancedPortfolioData).map((value) => value / 100),
            labels: Object.entries(balancedPortfolioData).map(([key, value]) =>
                formatLabel(determineLegendLabel(key), value)
            ),
            type: 'pie',
            hole: 0.4,
            textinfo: 'none', // Hide percentage labels inside the pie chart
            insidetextorientation: 'radial', // Ensure text is oriented properly
            marker: {
                colors: pieChartColors.slice(0, balancedPortfolioData.length) // Use the defined colors for the pie chart
            },
            domain: {
                x: [
                    0,
                    1
                ], // Increase the size of the pie chart horizontally
                y: [
                    0.25,
                    1
                ] // Increase the size of the pie chart vertically, starting higher up
            }
        }
    ];

    const balancedPortfolioChartLayout = {
        title: {
            text: `${customizeSettings.activePortfolioType} Portfolio %`,
            y: 0.85, // Adjust this value to move the title closer to the chart
            yanchor: 'bottom', // Anchor the title at the bottom to move it closer to the chart
            font: {
                size: 14
            }
        },
        showlegend: true,
        legend: {
            orientation: 'h', // Legend orientation (horizontal)
            xanchor: 'center', // Center the legend horizontally
            yanchor: 'bottom', // Anchor the legend at the top
            x: 0.5,
            y: 0.16, // Adjust this value to move the legend closer to the chart
            font: {
                size: 10 // Adjust the size as needed
            }
        },
        margin: {
            t: 0, // Remove top margin
            b: 0, // Remove bottom margin
            r: 10, // Right margin
            l: 10 // Left margin
        },
        height: 400 // Adjust height if needed,

    };
    // END REGION: Portfolio (balanced)

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <div style={{ width: '100%', minWidth: '300px' }}>
                {!allDisabled && (
                    <Plot
                    divId='PlotlyGraph'
                        data={[
                            ...chartData, // Existing chart data
                            ...(customizeSettings.chartTypes.Recessions ? [
                                {
                                    // Recessions legend item
                                    x: [-10000],  // Hide line
                                    y: [-10000],
                                    mode: 'lines',
                                    line: {
                                        color: '#e0e0e0',
                                        width: 10,
                                    },
                                    showlegend: customizeSettings.chartTypes.Recessions,
                                    name: `Recessions<br>(Vertical Bars)<br>National Bureau of<br> Economic Research`,
                                    hoverinfo: 'skip',
                                    legend: {
                                        orientation: 'v',
                                        y: 1.05,
                                    },
                                }
                            ] : []) // Add nothing if `recession` is null
                        ]}
                        layout={{
                            height: 1000,
                            yaxis: customizeSettings.chartTypes.SBBI
                                ? {
                                    domain: [0.5, 1],
                                    title: {
                                        text: 'Growth of $1 (with no acquisition costs or taxes and all income reinvested)',
                                        font: { size: 11 },
                                    },
                                    type: 'log',
                                    tickvals: sbbiYAxisTicks.tickValues,
                                    ticktext: sbbiYAxisTicks.tickText,
                                }
                                : {
                                    domain: [0, 0], // Hides yaxis when SBBI is false by collapsing its domain
                                    title: '',
                                    tickvals: [],
                                    ticktext: [],
                                },
                            yaxis2: {
                                domain: customizeSettings.chartTypes.SBBI ? [0.35, 0.45] : [0.9, 1],
                            },
                            yaxis3: {
                                domain: positionCommodities(),
                                title: 'Oil',
                                side: 'left',
                            },
                            yaxis4: {
                                domain: positionCommodities(),
                                title: 'Gold',
                                side: 'right',
                            },
                            yaxis5: {
                                title: 'Prime Interest',
                                domain: positionInterestVsInflation(), // Ensure the entire range is visible
                                side: 'left',
                                range: [-15, 26], // This overlays them nicely. TODO: params or declarative
                            },
                            yaxis6: {
                                title: 'Inflation',
                                domain: positionInterestVsInflation(),
                                side: 'right',
                            },
                            xaxis: {
                                range: ['1926-01-01', '2024-06-01'],
                                domain: [0, 1],
                                ticks: 'outside',
                                side: 'top',
                                anchor: 'y1',
                            },
                            xaxis2: {
                                range: ['1926-01-01', '2024-06-01'],
                                domain: [0, 1],
                                ticks: 'outside',
                                side: 'bottom',
                                anchor: 'y6',
                            },
                            margin: { r: 220, t: 30, b: 30, l: 70 },
                            showlegend: true,
                            legend: {
                                orientation: 'v',
                                y: 1.05,
                            },
                            legend2: {
                                orientation: 'h',
                                xanchor: 'left',
                                yanchor: 'top',
                                y: customizeSettings.chartTypes.SBBI ? 0.5 : 1.05,
                                x: 0,
                            },
                            annotations: annotations,
                            shapes: (customizeSettings.chartTypes.Recessions ? recessionShapes : ''),
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent',
                        }}
                        useResizeHandler={true}
                        style={{ width: '100%', height: '100%' }}
                        config={{ modeBarButtonsToRemove: ['toImage','lasso2d']}}
                    />
                )}
            </div>
            <div
                style={{
                    height: '150px',
                    width: '170px',
                    position: 'absolute',
                    top: positionDoughnutGraphVertically(),
                    right: 2,
                }}
            >
                {customizeSettings.chartTypes.SBBI && (
                <Plot
                    data={balancedPortfolioChartData}
                    layout={balancedPortfolioChartLayout}
                    style={{ width: '95%', height: '100%' }}
                    config={{ displayModeBar: false, useResizeHandler: true }}
                />
                )}
            </div>
        </div>
    );
}

export default React.memo(FullAndexChart); // Memo to keep charts from redrawing when exporting image
