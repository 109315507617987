import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import ChartsTab from './ChartsTab';
import SettingsTab from './SettingsTab';
import PortfolioTab from './PortfolioTab';
import { useContext } from 'react';
import {ReactComponent as XButton} from '../../../images/x_icon.svg';
import { CustomizeContext } from '../../../context/CustomizeContext';

const CustomizePopup = () => {
  const { 
    customizeSettings,
		setIsCustomizeMenuVisible,
    setActiveTab
	} = useContext(CustomizeContext);

    const renderTabContent = () => {
        switch (customizeSettings.activeTab) {
          case 'Settings':
            return <SettingsTab />
          case 'Charts':
            return <ChartsTab />;
          case 'Portfolio':
            return <PortfolioTab />;
          default:
            return null;
        }
      };

    return(
    <div className="customize-menu">
          <div className="customize-menu-header font-weight-600">
            <h3>Customize</h3>
            <button className="close-button" onClick={() => setIsCustomizeMenuVisible(false)}><XButton /></button>
          </div>
          <div className="customize-tabs">
            <button 
              className={customizeSettings.activeTab === 'Settings' ? 'active' : ''} 
              onClick={() => setActiveTab('Settings')}>
              Settings
            </button>
            <button 
              className={customizeSettings.activeTab === 'Charts' ? 'active' : ''} 
              onClick={() => setActiveTab('Charts')}>
              Charts
            </button>
            <button 
              className={customizeSettings.activeTab === 'Portfolio' ? 'active' : ''} 
              onClick={() => setActiveTab('Portfolio')}>
              Portfolio
            </button>
          </div>
          <div className="tab-content-container divider">
            {renderTabContent()}
          </div>
        </div>
    )
}
export default CustomizePopup