import React, { useState } from 'react';
import menuToggle from '../../images/menu_toggle.png';
import menuToggleClosed from '../../images/menu_toggle_closed.svg';
import userProfilePicture from '../../images/user_panel.jpg';
import languageSwitcher from '../../images/language_switcher.png';
import '../../css/Header/Header.css';

import NavLanguageDropdown from '../Nav/NavLanguageDropdown/NavLanguageDropdown';
import LogoutButton from '../Buttons/LogoutButton';

const Header = ({ toggleSideMenu, isSideMenuOpen }) => {
	const [
		languageDropdownVisible,
		setLanguageDropdownVisible
	] = useState(false);

	const toggleLanguageDropdown = () => {
		setLanguageDropdownVisible(!languageDropdownVisible);
	};

	return (
		<div className="header">
			<div className="left-section">
				<img
					src={isSideMenuOpen ? menuToggle : menuToggleClosed}
					alt="Menu Toggle"
					className="menu-toggle"
					onClick={toggleSideMenu}
				/>
				<span className="header-text">Andex chart</span>
			</div>
			{/* Out of scope for MVP */}
			{/* <div className="right-section">
        <img
          src={languageSwitcher}
          alt="Language Switcher"
          className="language-switcher"
          onClick={toggleLanguageDropdown}
        />
        <img
          src={userProfilePicture}
          alt="User Profile Picture"
          className="user-profile-picture"
        />
        {languageDropdownVisible && <NavLanguageDropdown />}
      </div> */}
			<LogoutButton />
		</div>
	);
};

export default Header;
